import {useAttendeeVariables} from 'Event'
import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import ColorPicker from 'lib/ui/ColorPicker'
import Divider from 'lib/ui/ConfigPanel/Divider'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function StepLabelConfig(props: {step: 1 | 2 | 3}) {
  const {step} = props
  const save = useTownhallUpdate()
  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const template = useTownhallTemplate()
  const {
    form: {control, register},
  } = useConfig()

  const handleSave = (data: any) => {
    save({
      checkIn: {
        ...data.checkIn,
        title: '{{check_in_title}}',
        [`step${step}Label`]: `{{check_in_step_${step}_label}}`,
      },
      localization: addTranslation({
        check_in_title: data.checkIn.title ?? '',
        [`check_in_step_${step}_label`]: data.checkIn[`step${step}Label`] ?? '',
      }),
    })
  }

  return (
    <Config title="Check In" onSave={handleSave}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="checkIn.title"
          label="Label"
          defaultValue={v(template.checkIn.title)}
          inputProps={{
            'aria-label': 'check in title',
            ref: register,
          }}
          fullWidth
        />
        <Divider />
        <Title>Step {step}</Title>
        <TextField
          name={`checkIn.step${step}Label`}
          label="Label"
          defaultValue={v(template.checkIn[`step${step}Label`])}
          inputProps={{
            'aria-label': 'step label',
            ref: register,
          }}
          fullWidth
        />
      </SettingsPanel>
      <StylingPanel>
        <Title>Title</Title>
        <Controller
          name="checkIn.titleColor"
          defaultValue={template.checkIn.titleColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="check in title color"
            />
          )}
        />
        <Title>Step</Title>
        <Controller
          name="checkIn.stepLabelColor"
          defaultValue={template.checkIn.stepLabelColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Color"
              color={value}
              onPick={onChange}
              aria-label="step label color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
