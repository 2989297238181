import Button from '@material-ui/core/Button'
import {NavButtonWithSize} from 'Event/Dashboard/components/NavButton'
import {useEditMode} from 'Event/EditModeProvider'
import React from 'react'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import {SidebarNavProps} from 'Event/template'
import {generateHashId} from 'lib/crypto/hash'

export default function NewSidebarNavButton(props: {
  className?: string
  nav: SidebarNavProps
}) {
  const isEditMode = useEditMode()
  const {update} = useEditSidebarItem()

  if (!isEditMode) {
    return null
  }

  const addButton = () => {
    const button: NavButtonWithSize = {
      text: 'Button',
      link: '',
      rules: [],
      size: 12,
      isAreaButton: false,
      isImageUpload: false,
      isFormButton: false,
      isCertificateButton: false,
      areaId: null,
      formId: null,
      actionId: null,
      certificateId: null,
      isVisible: true,
      infusionsoftTag: null,
      mailchimpTag: null,
      zapierTag: null,
      highLevelTag: null,
      webhook: null,
      disableHover: true,
      backgroundColor: '#000000',
      hoverBackgroundColor: '#000000',
      hubspotTag: null,
      activeCampaignTag: null,
      convertKitTag: null,
      ontraportTag: null,
    }

    generateHashId([
      'lefty_',
      'sidebar_item',
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      update({
        buttons: {
          [id]: button,
        },
      })
    })
  }
  return (
    <Button
      fullWidth
      size="large"
      variant="contained"
      color="secondary"
      aria-label="add sidebar nav button"
      onClick={addButton}
      className={props.className}
    >
      New Button
    </Button>
  )
}
