import React from 'react'
import {usePurchase} from '../../PurchaseContext'
import {formatPrice} from '../../lib/currency'
import {replace} from '../../lib/template'
import {Column} from '../../lib/layout'
import {Font} from '../../lib/font'

interface PurchaseButtonProps {
  disabled?: boolean
  ButtonComponent: React.FC<any>
  buttonStyle: {
    size: Column
    font: Font | null
    fontSize?: number
    backgroundColor?: string
    hoverBackgroundColor?: string
    textColor?: string
    hoverTextColor?: string
    borderRadius?: number
    borderWidth?: number
    borderColor?: string
    hoverBorderColor?: string
    height?: number
    width?: number
    disabledBackgroundColor?: string
    disabledTextColor?: string
  }
  purchaseButton: {
    text: string
    icon?: string | null
    iconAlign?: 'left' | 'right'
    image?: string
    margin?: {
      top: number
      right: number
      bottom: number
      left: number
    }
  }
  onClick?: () => void
}

export default function PurchaseButton(props: PurchaseButtonProps) {
  const {disabled, ButtonComponent, ...config} = props
  const {selectedTicket} = usePurchase()
  const textWithTicket = useTextWithTicketVariables(config.purchaseButton.text)
  const text = selectedTicket
    ? textWithTicket
    : 'Please Select Your Ticket Type'

  return (
    <ButtonComponent
      {...config}
      text={text}
      disabled={disabled}
      aria-label="purchase button"
      type="submit"
    />
  )
}

function useTextWithTicketVariables(text: string) {
  const {selectedTicket} = usePurchase()

  if (!selectedTicket) {
    return text
  }

  const attributes = {
    ticket_name: selectedTicket.name,
    ticket_price: `$${formatPrice(selectedTicket.price)}`,
  }
  let result = text
  // Replace attributes we know about.
  for (const [key, value] of Object.entries(attributes)) {
    result = replace(key, String(value), result)
  }
  return result
}
