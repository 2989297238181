import React from 'react'
import {FAQ} from 'Event/FAQs'
import Card from 'lib/ui/Card'
import Box from '@material-ui/core/Box'
import CardHeader from 'lib/ui/Card/CardHeader'
import CardContent from 'lib/ui/Card/CardContent'
import Collapse from '@material-ui/core/Collapse'
import {useAttendeeVariables} from 'Event'
import Content from 'lib/ui/form/TextEditor/Content'
import FaqConfig from 'Event/FAQs/FAQConfig'
import Configurable from 'organization/Event/Configurable'
import styled from 'styled-components'
import {Icon} from 'lib/fontawesome/Icon'
import {useTownhallTemplate} from 'Event/template/Townhall'

type BodyProps = {
  faq: FAQ
  className?: string
  id: string
  answerVisible?: boolean
}

export default function Body(props: BodyProps) {
  return (
    <Configurable>
      <FaqConfig faq={props.faq} id={props.id} />
      <FaqCard {...props} />
    </Configurable>
  )
}

function FaqCard(props: BodyProps) {
  const {faq, answerVisible} = props

  const {
    faq: {backgroundColor, titleColor, questionColor},
  } = useTownhallTemplate()

  const startExpanded =
    Boolean(props.answerVisible) || Boolean(faq.showAnswerOnLoad)

  const [expanded, setExpanded] = React.useState(startExpanded)
  const v = useAttendeeVariables()

  const handleExpandClick = () => {
    if (answerVisible) {
      return
    }

    setExpanded(!expanded)
  }

  return (
    <Container
      backgroundColor={backgroundColor}
      onClick={() => {
        handleExpandClick()
      }}
    >
      <Card>
        <CardHeaderContainer>
          <StyledCardHeader title={v(faq.question)} color={titleColor} />
          <ArrowIcon
            iconClass={
              expanded
                ? 'fa-solid fa-square-arrow-up'
                : 'fa-solid fa-square-arrow-down'
            }
          />
        </CardHeaderContainer>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <StyledCardContent color={questionColor}>
            <Content>{v(faq.answer)}</Content>
          </StyledCardContent>
        </Collapse>
      </Card>
    </Container>
  )
}

const Container = styled(Box)<{backgroundColor: string}>`
  // background-color: ${(props) => props.theme.colors.gray};
  background-color: ${(props) => props.backgroundColor};
  margin-bottom: 20px;
  padding: 20px;
`

const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledCardHeader = styled(CardHeader)<{color: string}>`
  color: ${(props) => props.color};
  padding: 0 !important;
`

const ArrowIcon = styled(Icon)`
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;
`

const StyledCardContent = styled(CardContent)<{color: string}>`
  border-bottom: none !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
  color: ${(props) => props.color};
  p {
    font-weight: 300;
  }
`
