import React, {CSSProperties} from 'react'

export default function CKEditorContent(props: {
  className?: string
  children: string
  'aria-label'?: string
  style?: CSSProperties
}) {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .ck-content > p{
            padding: 0!important;
            margin: 0!important;
          }
        `,
        }}
      />

      <div
        /**
         * '.ck-content' class required for CKEditor styles to take affect. ie. for
         * image alignment.
         *
         * Reference: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
         */
        className={`ck-content ${props.className}`}
        dangerouslySetInnerHTML={{
          __html: props.children,
        }}
        style={props.style}
        aria-label={props['aria-label']}
      />
    </>
  )
}
