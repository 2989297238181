'use client'

import React, {useLayoutEffect, useMemo, useState} from 'react'
import {Responsive, WidthProvider} from 'react-grid-layout'
import {rgba} from './lib/color'
import {useToggleArray} from './lib/use-toggle'
import {PurchasePageSection} from './purchase-page'
import {backgroundRepeat, backgroundSize} from './lib/background'
import classNames from 'classnames'
import styles from './Section.module.css'
import Block from './Block'
import {getBlocks} from './Block/get-blocks'
import {useResizedLayouts} from './lib/use-resize-layout'
import {PageTemplate} from './page-template'

interface SectionProps {
  section: PurchasePageSection
  ResponsiveReactGridLayoutProps?: ReactGridLayout.ResponsiveProps &
    ReactGridLayout.WidthProviderProps
  template: PageTemplate
  children?: React.ReactNode
  isMobileMode?: boolean
  disableItemResize?: boolean
  onSizeInit?: () => void
  minTopPadding?: number
}

export const sectionRowHeight = 10

const SectionContext = React.createContext<undefined | SectionContextProps>(
  undefined,
)

interface SectionContextProps {
  calculateVisibility: () => void
}

export default function Section(props: SectionProps) {
  const {
    section,
    ResponsiveReactGridLayoutProps = {},
    children,
    isMobileMode = false,
    disableItemResize = false,
    template,
    minTopPadding = 0,
    onSizeInit,
  } = props

  const {layouts, numColumns, blockSpacing} = section
  const {contentMaxWidth} = template
  const [onRerender, rerender] = useToggleArray()
  const [breakpoint, setBreakpoint] = useState<string | null>(null)

  // Fix bug where API sends back an empty object as an array causing
  // layouts to error
  const layoutsObject = Array.isArray(layouts)
    ? {
        desktop: layouts['desktop'] ?? [],
        mobile: layouts['mobile'] ?? [],
      }
    : layouts

  // Make it work with old verion, by adding defaults
  const layoutsObjectWithDefaults = {
    ...layoutsObject,
    mobile: layoutsObject.mobile.map((layout) => {
      return {
        ...layout,
        w: layout.w ?? 2,
        h: layout.h ?? 10,
      }
    }),
    desktop: layoutsObject.desktop.map((layout) => {
      return {
        ...layout,
        w: layout.w ?? 2,
        h: layout.h ?? 10,
      }
    }),
  }

  const containerStyles = getContainerStyles(section, {
    minTopPadding,
    isMobileMode,
  })
  const contentStyles = getContentStyles(section, {contentMaxWidth})

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), [])

  const resizedLayouts = useResizedLayouts({
    layoutsObject: layoutsObjectWithDefaults,
    blockSpacing,
    shouldRerender: onRerender,
    rowHeight: sectionRowHeight,
    onSizeInit,
    breakpoint,
  })

  useLayoutEffect(() => {
    if (breakpoint) {
      return
    }

    const currentBreakpoint = window.innerWidth > 1280 ? 'desktop' : 'mobile'
    setBreakpoint(currentBreakpoint)
  }, [breakpoint])

  return (
    <SectionContext.Provider value={{calculateVisibility: rerender}}>
      <div
        style={containerStyles}
        className={classNames({
          [styles.mobileOnly]: section.visibility === 'mobile_only',
          [styles.desktopOnly]: section.visibility === 'desktop_only',
        })}
      >
        <ResponsiveReactGridLayout
          breakpoints={
            // On mobile mode we'll just set desktop breakpoint to something ridiculously high, i.e, forcing
            // it to always be editing mobile.
            isMobileMode
              ? {desktop: 10000000000, mobile: 0}
              : {desktop: 960, mobile: 0}
          }
          layouts={
            disableItemResize ? layoutsObjectWithDefaults : resizedLayouts
          }
          cols={{desktop: numColumns ?? 24, mobile: 1}}
          isDraggable={false}
          isResizable={false}
          rowHeight={sectionRowHeight}
          margin={[section.blockSpacing ?? 10, section.blockSpacing ?? 10]}
          style={{
            width: '100%',
            ...contentStyles,
          }}
          {...ResponsiveReactGridLayoutProps}
          onBreakpointChange={(breakpoint, columns) => {
            setBreakpoint(breakpoint)
            if (ResponsiveReactGridLayoutProps.onBreakpointChange) {
              ResponsiveReactGridLayoutProps.onBreakpointChange(
                breakpoint,
                columns,
              )
            }
          }}
        >
          {children ||
            Object.entries(getBlocks(section)).map(([id, block]) => (
              <Block block={block} key={id} id={id} template={template} />
            ))}
        </ResponsiveReactGridLayout>
      </div>
    </SectionContext.Provider>
  )
}

function getContainerStyles(
  section: PurchasePageSection,
  options: {
    minTopPadding: number
    isMobileMode: boolean
  },
): React.CSSProperties {
  return {
    position: 'relative',
    backgroundColor: rgba(
      section.background.color || '#FFFFFF',
      section.background.opacity / 100,
    ),
    backgroundImage:
      section.background && section.background.image
        ? `url(${section.background.image})`
        : undefined,
    boxShadow: `inset 0 0 0 100cqh ${rgba(
      section.background.color || '#FFFFFF',
      section.background.opacity / 100,
    )}`,
    backgroundPosition:
      section.background.repeat === 'full-center-parallax'
        ? 'center'
        : section.background.position,
    backgroundSize: backgroundSize(section.background.repeat),
    backgroundAttachment:
      section.background.repeat === 'full-center-parallax'
        ? 'fixed'
        : undefined,
    backgroundRepeat: backgroundRepeat(section.background.repeat),
    border: `${section.border.width}px solid ${section.border.color}`,
    borderRadius: `${section.border.radius}px`,
    paddingTop: `${Math.max(options.minTopPadding, section.padding.top)}px`,
    paddingRight: `${section.padding.right}px`,
    paddingBottom: `${section.padding.bottom}px`,
    paddingLeft: `${section.padding.left}px`,
    marginTop: `${section.margin.top}px`,
    marginRight: `${section.margin.right}px`,
    marginBottom: `${section.margin.bottom}px`,
    marginLeft: `${section.margin.left}px`,
    minHeight: `${section.minHeight}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}

function getContentStyles(
  section: PurchasePageSection,
  options: {
    contentMaxWidth: number
  },
) {
  if (section.fullWidth) return null

  return {
    backgroundColor: rgba(
      section.content.background.color || '#FFFFFF',
      section.content.background.opacity / 100,
    ),
    backgroundImage:
      section.content.background && section.content.background.image
        ? `url(${section.content.background.image})`
        : undefined,
    outline: ` ${section.content.border.color} solid ${section.content.border.width}px `,
    boxShadow: `inset 0 0 0 100cqh ${rgba(
      section.content.background.color || '#FFFFFF',
      section.content.background.opacity / 100,
    )}`,
    backgroundPosition: section.content.background.position,
    backgroundSize: '100% auto',
    backgroundRepeat: 'no-repeat',
    borderRadius: `${section.content.border.radius}px`,
    maxWidth: section.fullWidth ? 'none' : `${options.contentMaxWidth}px`,
  }
}

export function useSection() {
  const context = React.useContext(SectionContext)

  if (!context) {
    throw new Error('useSection must be used within Section')
  }

  return context
}
