import React, {useCallback} from 'react'
import {useForm} from 'react-hook-form'
import {useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Cards/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/CountDownTimers/CountDownTimer/TimerConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
import {generateHashId} from 'lib/crypto/hash'

export default function TimerConfig(
  props: ComponentConfigProps & {
    countDownTimer: CountDownTimerSettings
    id?: string
  },
) {
  const {showing: isVisible, onClose, id, countDownTimer} = props

  const {control, handleSubmit, watch, formState} = useForm()

  const updateTemplate = useCardsUpdate()

  const update = (id: string, updated: CountDownTimerSettings) => {
    updateTemplate({
      countDownTimers: {
        [id]: updated,
      },
    })
  }

  const insert = (countDownTimer: CountDownTimerSettings) => {
    generateHashId([
      'cards_',
      'ticket_ribbon',
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      updateTemplate({
        countDownTimers: {
          [id]: countDownTimer,
        },
      })
    })
  }

  const removeCountDownTimer = useCallback(() => {
    if (!id) {
      throw new Error('Missing count down timer id')
    }

    updateTemplate({
      countDownTimers: {
        [id]: REMOVE,
      },
    })
  }, [updateTemplate, id])

  const save = (formData: CountDownTimerSettings) => {
    if (id) {
      update(id, formData)
    } else {
      insert(formData)
    }
    onClose()
  }

  const isUpdate = Boolean(id)

  useAutoUpdate({
    values: id ? {countDownTimers: {[id]: watch()}} : {},
    when: isUpdate,
  })

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Count Down Timer Config"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} countDownTimer={countDownTimer} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} countDownTimer={countDownTimer} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove countdown"
          onClick={removeCountDownTimer}
          showing={isUpdate}
        />
      </Footer>
    </ComponentConfig>
  )
}
