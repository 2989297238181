import React, {useCallback, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import {useAsync} from 'lib/async'
import FullPageLoader from 'lib/ui/layout/FullPageLoader'
import {api, useQueryParams} from 'lib/url'
import BlockListItem from 'organization/Obie/Blocks/BlockListItem'
import {useObieService, Block} from 'organization/Obie/ObieServiceProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import CategoryProfileSelector from '../CategoryProfileSelector'

export default function BlockList() {
  const {sessionId: session_id} = useQueryParams()

  const {category: category_id, profile: profile_id} = useParams<{
    category: string
    profile: string
  }>()
  const {
    blocks,
    categories,
    categoryId,
    clearBlocks,
    fetchBlocks,
    fetchCompletions,
    loadingBlocks,
    profileId,
    setCategoryId,
    setProfileId,
  } = useObieService()

  const {data: allBlocks} = useAllBlocks()

  const category = categories.find(
    (category) => category.id === parseInt(category_id),
  )

  useEffect(() => {
    if (!category_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setCategoryId(parseInt(category_id))
  }, [setCategoryId, category_id])

  useEffect(() => {
    if (!profile_id) {
      return
    }

    // This is the beginning of the Profile-aware bits, so let's set it into state
    // so we don't have to pass it around through props.
    setProfileId(parseInt(profile_id))
  }, [setProfileId, profile_id])

  // Whenever the categoryId or profileId in the provider changes, we clear the
  // block state. Don't want to do this automagically in the provider, for down-
  // stream potential problems.
  useEffect(() => {
    clearBlocks()
  }, [categoryId, clearBlocks, profileId])

  useEffect(() => {
    // We need a categoryId and a profileId before we can fetchBlocks.
    if (!categoryId || profileId === undefined) {
      return
    }

    // If we don't already have blocks and we're not currently in the process of
    // fetching blocks, do it.
    if (!blocks.length && !loadingBlocks) {
      fetchBlocks(categoryId, profileId)
      fetchCompletions()
    }
  }, [
    blocks,
    categoryId,
    loadingBlocks,
    profileId,
    session_id,
    fetchBlocks,
    fetchCompletions,
  ])

  if (!category) {
    return null
  }

  if (loadingBlocks || !allBlocks || !blocks) {
    return <FullPageLoader />
  }

  return (
    <Container>
      <TitleBox>
        <Title>
          <CategoryProfileSelector category={category} profileId={profileId} />
        </Title>
      </TitleBox>
      {blocks.map((block: Block, index: number) => (
        <BlockListItem key={index} block={block} blocks={allBlocks} />
      ))}
    </Container>
  )
}

function useAllBlocks() {
  const {client, organization} = useOrganization()
  const {id: organizationId} = organization

  const request = useCallback(
    async () =>
      client.get<Block[]>(api(`/organizations/${organizationId}/obie/blocks`)),

    [client, organizationId],
  )

  return useAsync(request)
}

const Container = styled.div`
  width: 100%;
  padding: 10px;
`

const TitleBox = styled.div`
  margin: 60px 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 20px 0;
  }
`

const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
`
