import React from 'react'
import {useForm} from 'react-hook-form'
import {Panels, usePanelsUpdate} from 'Event/template/Panels'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import Settings from 'Event/template/Panels/ImageWaterfall/ImageWaterfallConfig/Settings'
import Styling from 'Event/template/Panels/ImageWaterfall/ImageWaterfallConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function ImageWaterfallConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const updateTemplate = usePanelsUpdate()
  const {control, register, handleSubmit, formState} = useForm()
  const addTranslation = useAddTranslation()

  const submit = (data: Panels['imageWaterfall']) => {
    const title = replaceAtPath(
      data,
      'imageWaterfall.title',
      '{{image_waterfall_title}}',
    )
    const menuTitle = replaceAtPath(
      data,
      'imageWaterfall.menuTitle',
      '{{image_waterfall_menu_title}}',
    )

    const uploadFormTitle = replaceAtPath(
      data,
      'imageWaterfall.uploadFormTitle',
      '{{image_waterfall_update_form_title}}',
    )

    const uploadButtonText = replaceAtPath(
      data,
      'imageWaterfall.uploadButtonText',
      '{{image_waterfall_update_button_text}}',
    )

    updateTemplate({
      imageWaterfall: {
        ...data,
      },
      localization: addTranslation({
        image_waterfall_title: title ?? 'Image Waterfall',
        image_waterfall_menu_title: menuTitle ?? 'Image Waterfall',
        image_waterfall_update_form_title: uploadFormTitle ?? 'Upload Image',
        image_waterfall_update_button_text: uploadButtonText ?? 'Upload',
      }),
    })

    onClose()
  }
  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Image Waterfall"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
