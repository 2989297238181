import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import Page from 'organization/Event/Page'
import TranslationForm from 'organization/Event/LocalizationConfig/TranslationForm'
import {Language} from 'Event/LanguageProvider/language'
import LanguageSelect from 'organization/Event/LocalizationConfig/LanguageSelect'
import {useLanguage} from 'Event/LanguageProvider'
import {Translations} from 'Event/LanguageProvider/translations'
import MakeDefaultButton from 'organization/Event/LocalizationConfig/MakeDefaultButton'
import Box from '@material-ui/core/Box'
import AddLanguageButton from 'organization/Event/LocalizationConfig/AddLanguageButton'
import EnableTranslationSwitch from 'organization/Event/LocalizationConfig/EnableTranslationSwitch'
import RemoveLanguageButton from 'organization/Event/LocalizationConfig/RemoveLanguageButton'
import SetRulesButton from 'organization/Event/LocalizationConfig/SetRulesButton'
import LocalizationExport from 'organization/Event/LocalizationConfig/LocalizationExport'
import LocalizationImport from 'organization/Event/LocalizationConfig/LocalizationImport'
import {FeaturesBreadcrumbs} from 'organization/Event/Page/PageBreadcrumbs'
import {useTemplate} from 'Event/TemplateProvider'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import AdvancedModeButton from './AdvancedModeButton'

type LocalizationConfigContextProps = {
  isProcessing: boolean
  setIsProcessing: (processing: boolean) => void
}

const LocalizationConfigContext = React.createContext<
  LocalizationConfigContextProps | undefined
>(undefined)

export default function LocalizationConfig() {
  const {defaultLanguage, isAdvancedMode} = useLanguage()
  const [language, setLanguage] = useState<Language['name']>(defaultLanguage)
  const [translations, setTranslations] = useState<Translations | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const remove = useRemoveLanguage()
  const template = useTemplate()

  useEffect(() => {
    const saved = template.localization?.translations

    const translations = saved || {}
    setTranslations(translations)
  }, [template])

  useEffect(() => {
    setLanguage(defaultLanguage)
  }, [defaultLanguage])

  if (!translations) {
    return null
  }

  const canRemove = language !== defaultLanguage && !isProcessing

  const handleRemove = () => {
    if (isProcessing) {
      return
    }
    setIsProcessing(true)
    remove(translations, language)
    setLanguage(defaultLanguage)
  }

  return (
    <FeaturesBreadcrumbs page="Translations">
      <Page>
        <LocalizationConfigContext.Provider
          value={{isProcessing, setIsProcessing}}
        >
          <Box mb={4} display="flex">
            <EnableTranslationSwitch />
            <AdvancedModeButton />
          </Box>
          <Box mb={2} display="flex" justifyContent="space-between">
            <ButtonsBox>
              <SetRulesButton language={language} />
              <MakeDefaultButton
                language={language}
                translations={translations}
              />
              <RemoveLanguageButton
                onRemove={handleRemove}
                canRemove={canRemove}
                language={language}
              />
            </ButtonsBox>
            <ButtonsBox>
              <AddLanguageButton onAdd={setLanguage} />
              {isAdvancedMode && (
                <>
                  <LocalizationExport />
                  <LocalizationImport />
                </>
              )}
            </ButtonsBox>
          </Box>
          <LanguageSelect
            value={language}
            onChange={setLanguage}
            showDefault
            disabled={isProcessing}
          />
          {isAdvancedMode && (
            <TranslationForm
              language={language}
              translations={translations}
              setTranslations={setTranslations}
            />
          )}
        </LocalizationConfigContext.Provider>
      </Page>
    </FeaturesBreadcrumbs>
  )
}

export function useLocalizationConfig() {
  const context = React.useContext(LocalizationConfigContext)
  if (context === undefined) {
    throw new Error(
      'useLocalizationConfig must be used within LocalizationConfig',
    )
  }

  return context
}

const ButtonsBox = styled.div`
  margin: ${(props) => `-${props.theme.spacing[1]}`}!important;

  > button {
    margin: ${(props) => props.theme.spacing[1]}!important;
  }
`

function useRemoveLanguage() {
  const {languages} = useLanguage()
  const saveTemplate = useSaveTemplate()

  return (translations: Translations, language: Language['name']) => {
    const removedLanguage = languages.filter((l) => l.name !== language)

    return saveTemplate({
      localization: {
        translations: {
          [language]: REMOVE,
        },
        languages: removedLanguage,
      },
    })
  }
}
