import React from 'react'
import {Cards, useCardsUpdate} from 'Event/template/Cards'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import Settings from 'Event/template/Cards/Dashboard/MainNav/MainNavConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function MainNavConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useCardsUpdate()
  const {handleSubmit, control, formState} = useForm()
  const addTranslation = useAddTranslation()

  const save = (data: Omit<Cards['mainNav'], 'buttons'>) => {
    const scrollDownText = replaceAtPath(
      data,
      'scrollDownText',
      '{{main_nav_scroll_down_text}}',
    )

    update({
      mainNav: data,
      localization: addTranslation({
        main_nav_scroll_down_text: scrollDownText ?? '',
      }),
    })
    onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Main Nav"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
