import ColorPicker from 'lib/ui/ColorPicker'
import {useTownhallTemplate} from 'Event/template/Townhall'
import React from 'react'
import {Controller} from 'react-hook-form'
import CollapsibleSection from 'lib/ui/ConfigPanel/CollapsibleSection'
import {useConfig} from 'organization/Event/Configurable'
import BackgroundPicker from 'lib/ui/form/BackgroundPicker'

export default function Styling() {
  const {
    form: {control},
  } = useConfig()
  const {faq} = useTownhallTemplate()

  return (
    <>
      <CollapsibleSection label="Card" storageKey="faq.card.styling">
        <Controller
          name="faq.backgroundColor"
          defaultValue={faq.backgroundColor}
          control={control}
          render={({value, onChange}) => (
            <BackgroundPicker
              label="Background"
              background={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          name="faq.titleColor"
          defaultValue={faq.titleColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Card Title Color"
              color={value}
              onPick={onChange}
              aria-label="card title color"
            />
          )}
        />
        <Controller
          name="faq.questionColor"
          defaultValue={faq.questionColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Card Question Text Color"
              color={value}
              onPick={onChange}
              aria-label="card question color"
            />
          )}
        />
      </CollapsibleSection>
    </>
  )
}
