import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'
import {useTownhallTemplate, useTownhallUpdate} from 'Event/template/Townhall'
import {StyleFields} from 'Event/template/Townhall/Login/Title/TitleConfig'
import Title from 'lib/ui/ConfigPanel/Title'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'

export default function TitleConfig() {
  const save = useTownhallUpdate()
  const {login} = useTownhallTemplate()

  const {
    form: {register},
  } = useConfig()

  const v = useAttendeeVariables()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    save({
      login: {
        welcome: {
          ...data.login.welcome,
          text: '{{login_welcome_text}}',
        },
        passwordReset: {
          forgotPasswordTitle: '{{login_password_reset_forgot_password_title}}',
        },
      },
      localization: addTranslation({
        login_password_reset_forgot_password_title:
          data.login.passwordReset.forgotPasswordTitle ?? '',
      }),
    })
  }
  return (
    <Config title="Title" onSave={handleSave}>
      <SettingsPanel>
        <Title>Title</Title>
        <TextField
          name="login.passwordReset.forgotPasswordTitle"
          defaultValue={v(login.passwordReset.forgotPasswordTitle)}
          label="Text"
          fullWidth
          inputProps={{
            'aria-label': 'text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <StyleFields />
      </StylingPanel>
    </Config>
  )
}
