import React from 'react'
import {Draggable} from 'react-beautiful-dnd'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {addMinutes, format} from 'date-fns'
import {EditComponentOverlay} from 'Event/Dashboard/editor/views/EditComponent'
import {Session, TYPE_BREAK} from 'organization/Obie/PagBuilder/types'
import {sessionRoute} from 'organization/Obie/PagRoutes'
import {useOrganization} from 'organization/OrganizationProvider'

interface SessionCardProps {
  pagId: number | undefined
  profileId: number | undefined
  session: Session
  dayIndex: number
  sessionIndex: number
  isDragDisabled?: boolean
}

export default function SessionCard(props: SessionCardProps) {
  const {
    pagId,
    profileId,
    session,
    dayIndex,
    sessionIndex,
    isDragDisabled,
  } = props
  const isBreak = session.type === TYPE_BREAK
  const history = useHistory()
  const {routes} = useOrganization()

  if (!pagId || profileId === undefined) {
    return null
  }

  const sessionRoutes = sessionRoute(
    routes,
    pagId,
    profileId,
    dayIndex,
    sessionIndex,
  )

  return (
    <Draggable
      draggableId={`${dayIndex}-${sessionIndex}`}
      index={sessionIndex}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <EditComponentOverlay
            onClick={() => {
              history.push(sessionRoutes.root)
            }}
            handleProps={provided.dragHandleProps}
          >
            <CardContainer isBreak={isBreak}>
              <ContentWrapper>
                <TextContent>
                  {isBreak ? (
                    <>
                      <Title>{session.name}</Title>
                      <GrayText>
                        Break Duration: {session.durationMins}m
                      </GrayText>
                    </>
                  ) : (
                    <>
                      <Title>{session.name}</Title>
                      <GrayText>
                        Time:{' '}
                        {format(new Date(session.startTime ?? ''), 'h:mm a')} -{' '}
                        {format(
                          addMinutes(
                            new Date(session.startTime ?? ''),
                            session.durationMins,
                          ),
                          'h:mm a',
                        )}
                      </GrayText>
                      <GrayText>Duration: {session.durationMins}m</GrayText>
                    </>
                  )}
                </TextContent>
              </ContentWrapper>
            </CardContainer>
          </EditComponentOverlay>
        </div>
      )}
    </Draggable>
  )
}

const CardContainer = styled.div<{isBreak: boolean}>`
  background-color: ${(props) => (props.isBreak ? '#3A4F8C' : '#222f53')};
  border: 1px dashed #ffffff;
  padding: 1rem;
  color: white;
  margin: 0.5rem 0;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  flex: 1;
`

const GrayText = styled.p`
  font-size: 0.875rem;
  color: #d1d5db;
  margin: 0;
`

const Title = styled.h3`
  font-weight: 600;
  margin: 0;
`
