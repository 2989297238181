import React, {useState} from 'react'
import {Grid} from '@material-ui/core'
import {
  Header,
  MenuLeftSide,
  NumHolder,
  QuestionAnswers,
  QuestionAttendee,
  QuestionData,
  QuestionNumber,
  QuestionLeft,
  QuestionRight,
  QuestionRow,
  QuestionsInlinePreview,
  FormSubmission,
} from 'organization/Event/Form/FormModeration/Question'
import MenuItem from 'lib/ui/Menu/MenuItem'
import Menu from 'lib/ui/Menu'
import Icon from 'lib/ui/Icon'
import IconButton from 'lib/ui/IconButton'
import Divider from '@material-ui/core/Divider'
import MoveQaBoard from 'assets/images/icons/move-qa-board.svg'
import SelectedSubmissionDialog from 'organization/Event/Form/FormModeration/SelectedSubmissionDialog'
import {useFormSubmissions} from 'lib/event-api/forms/use-form-submissions'
import {useForm} from 'organization/Event/Form/FormProvider'
import {useMoveSubmission} from 'organization/Event/Form/FormModeration/QATab/use-move-submission'
import {sortSubmissionsReversed} from 'organization/Event/Form/FormModeration/QATab/sort-submissions'

export default function QAAnswered() {
  const {form} = useForm()

  const {data: submissions = []} = useFormSubmissions({
    form,
    status: 'answered',
  })

  const moveSubmission = useMoveSubmission({form})

  const [dialogVisible, setDialogVisible] = useState(false)
  const [dialogQuestion, setDialogQuestion] = useState<FormSubmission | null>(
    null,
  )

  const toggleDialog = () => setDialogVisible(!dialogVisible)

  const showFullResponse = (formSubmission: FormSubmission) => {
    setDialogQuestion(formSubmission)
    setDialogVisible(true)
  }

  return (
    <>
      <SelectedSubmissionDialog
        formSubmission={dialogQuestion}
        onClose={toggleDialog}
        showing={dialogVisible}
      />
      <Grid container>
        <Header item xs={12}>
          <MenuLeftSide>
            ANSWERED <NumHolder>{submissions.length}</NumHolder>
          </MenuLeftSide>
        </Header>

        {submissions.sort(sortSubmissionsReversed).map((submission, index) => {
          return (
            <QuestionRow key={index}>
              <QuestionNumber>{submission.number}</QuestionNumber>
              <QuestionData>
                <QuestionLeft>
                  <QuestionAttendee>
                    {submission.attendee_first_name}{' '}
                    {submission.attendee_last_name}
                  </QuestionAttendee>
                  <QuestionAnswers>
                    <QuestionsInlinePreview questions={submission.answers} />
                  </QuestionAnswers>
                </QuestionLeft>
                <QuestionRight>
                  <Menu
                    button={({open}) => (
                      <IconButton onClick={open} aria-label="view form actions">
                        <Icon
                          className="fas fa-ellipsis-h"
                          color="black"
                          iconSize={18}
                        />
                      </IconButton>
                    )}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem
                      icon={MoveQaBoard}
                      children="View Full Response "
                      onClick={() => {
                        showFullResponse(submission)
                      }}
                    />
                    <Divider />
                    <MenuItem
                      icon={MoveQaBoard}
                      children="Move to Q&A Board"
                      onClick={() => {
                        moveSubmission({
                          fromStatus: 'answered',
                          fromIndex: index,
                          toStatus: 'incoming',
                          toIndex: 0,
                        })
                      }}
                    />
                    <Divider />
                    <MenuItem
                      icon={MoveQaBoard}
                      children="Move to Trash"
                      onClick={() => {
                        moveSubmission({
                          fromStatus: 'answered',
                          fromIndex: index,
                          toStatus: 'deleted',
                          toIndex: -1,
                        })
                      }}
                    />
                  </Menu>
                </QuestionRight>
              </QuestionData>
            </QuestionRow>
          )
        })}
      </Grid>
    </>
  )
}
