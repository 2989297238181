import {useCallback} from 'react'
import {Waiver as WaiverProps} from 'Event/template'
import {useAttendeeProfile} from 'Event/attendee-rules/AttendeeProfileProvider'
import {useTemplate} from 'Event/TemplateProvider'
import {hasMatch} from 'Event/attendee-rules/matcher'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {orderedIdsByPosition} from 'lib/list'
import React from 'react'
import Waiver from 'Event/Step2/Waivers/Waiver'
import {useEvent} from 'Event/EventProvider'
import {hasSubmission, useSubmissions} from 'Event/SubmissionsProvider'
import {User} from 'auth/user'
import {generateHashId} from 'lib/crypto/hash'

const DEFAULT_BODY = `<div>
<p>This is where your waiver text would go should you decide to include this step in your attendee check in process.</p>
<p>
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Suspendisse potenti nullam ac tortor vitae purus faucibus ornare suspendisse. Odio ut enim blandit volutpat. Pellentesque habitant morbi tristique senectus et netus et malesuada fames. Duis tristique sollicitudin nibh sit. Ligula ullamcorper malesuada proin libero nunc. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan. Sit amet risus nullam eget felis eget nunc lobortis. Feugiat scelerisque varius morbi enim nunc. Sed velit dignissim sodales ut eu. Diam maecenas ultricies mi eget mauris pharetra et ultrices neque. Nisl nisi scelerisque eu ultrices. At risus viverra adipiscing at. Sed risus ultricies tristique nulla aliquet. Lectus arcu bibendum at varius. Feugiat scelerisque varius morbi enim nunc. Urna nec tincidunt praesent semper feugiat nibh sed.
Id diam vel quam elementum pulvinar etiam. Ut pharetra sit amet aliquam id diam. Congue nisi vitae suscipit tellus mauris. Vitae et leo duis ut. Nam aliquam sem et tortor consequat id. Convallis posuere morbi leo urna. Nullam eget felis eget nunc. Id venenatis a condimentum vitae sapien. Enim sed faucibus turpis in. In est ante in nibh mauris cursus mattis molestie a. Turpis massa sed elementum tempus egestas sed. Nulla facilisi etiam dignissim diam quis enim lobortis. Quis blandit turpis cursus in hac habitasse platea dictumst. Semper viverra nam libero justo. Eget est lorem ipsum dolor sit amet consectetur.
Eros donec ac odio tempor orci dapibus. Varius duis at consectetur lorem donec massa sapien. Eget est lorem ipsum dolor sit amet consectetur adipiscing elit. Elit ullamcorper dignissim cras tincidunt lobortis. Aliquet lectus proin nibh nisl condimentum. Bibendum arcu vitae elementum curabitur vitae nunc sed velit dignissim. At quis risus sed vulputate odio. Scelerisque eleifend donec pretium vulputate. Malesuada proin libero nunc consequat interdum varius. Aliquam id diam maecenas ultricies mi eget mauris. Amet cursus sit amet dictum sit. Dolor magna eget est lorem ipsum dolor sit.
</p>
`
export const DEFAULT_AGREE_STATEMENT = `I, {{first name}} {{last name}}, hereby certify that I have read the forgoing and fully understand the meaning effect thereof, and intending to be legally bound, have signed it.`
export const DEFAULT_SIGNATURE_PROMPT = `Please Sign Below with your finger or mouse.`

export default function Waivers(props: {user: User}) {
  const {
    waiver: {targets},
  } = useTemplate()
  const ids = orderedIdsByPosition(targets)

  const waivers = ids.map((id) => (
    <Waiver key={id} waiver={targets[id]} id={id} user={props.user} />
  ))

  return <>{waivers}</>
}

export function useTargetWaiverId() {
  const template = useTemplate()
  const profile = useAttendeeProfile()
  const {event} = useEvent()
  const {forms} = event
  const {answers} = useSubmissions()
  const waivers = template.waiver.targets

  const ids = orderedIdsByPosition(template.waiver.targets)

  const waiversEnabled = template.waiver.isEnabled
  if (!waiversEnabled) {
    return null
  }

  const hasWaivers = ids.length > 0
  if (!hasWaivers) {
    return null
  }

  // We always want to show a waiver, so we'll set the first one as the default.
  const defaultWaiver = ids[0]

  if (!event.allows_additional_waivers) {
    return defaultWaiver
  }

  const matches = ids.filter((id) =>
    hasMatch({...profile, forms, answers}, waivers[id].rules),
  )
  const hasMatches = matches.length > 0
  if (hasMatches) {
    // Return the first match as the target waiver
    return matches[0]
  }

  // If we can't find a matching waiver, we'll always fallback to the default
  return defaultWaiver
}

export function useHasWaiver() {
  const id = useTargetWaiverId()
  return Boolean(id)
}

export function useHasWaiverFormAnswers() {
  const {answers} = useSubmissions()

  const waiverForm = useWaiverForm()
  if (!waiverForm) {
    return false
  }

  return hasSubmission(answers, waiverForm)
}

export function useWaiverForm() {
  const template = useTemplate()
  const targetWaiverId = useTargetWaiverId()
  const {
    event: {forms},
  } = useEvent()

  if (!targetWaiverId) {
    return null
  }

  const target = forms.find(
    (f) => f.id === template.waiver.targets[targetWaiverId].formId,
  )

  return target
}

export function useAddWaiver() {
  const {
    waiver: {targets},
  } = useTemplate()
  const numWaivers = Object.keys(targets).length
  const save = useSaveTemplate()

  return useCallback(() => {
    const waiver: WaiverProps = {
      title: '',
      body: DEFAULT_BODY,
      formId: null,
      agreeStatement: DEFAULT_AGREE_STATEMENT,
      signaturePrompt: DEFAULT_SIGNATURE_PROMPT,
      rules: [],
      position: numWaivers + 1,
    }

    generateHashId([
      'waiver_',
      (numWaivers + 1).toString(),
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      save({
        waiver: {
          targets: {
            [id]: waiver,
          },
        },
      })
    })
  }, [save, numWaivers])
}
