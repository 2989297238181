import {useLanguage} from 'Event/LanguageProvider'
import {Template} from 'Event/template'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {onChangeCheckedHandler} from 'lib/dom'
import Switch from 'lib/ui/form/Switch'
import React from 'react'

export default function AdvancedModeButton() {
  const {isAdvancedMode, translationsEnabled} = useLanguage()
  const setTranslationAdvancedMode = useSetAdvancedMode()

  if (!translationsEnabled) {
    return null
  }

  return (
    <Switch
      checked={isAdvancedMode}
      onChange={onChangeCheckedHandler(setTranslationAdvancedMode)}
      aria-label="toggle translations advanced mode"
      labelPlacement="end"
      label="Advanced Mode"
    />
  )
}

function useSetAdvancedMode() {
  const saveTemplate = useSaveTemplate()

  return (enabled: boolean) => {
    const localization: Partial<Template['localization']> = {
      isAdvancedMode: enabled,
    }

    return saveTemplate({
      localization,
    })
  }
}
