import {useLanguage} from 'Event/LanguageProvider'
import {Language, ENGLISH} from 'Event/LanguageProvider/language'
import {systemDefault} from 'Event/LanguageProvider/system'
import {useTemplate} from 'Event/TemplateProvider'
import {replace, parseVariables} from 'lib/template'
import {useAllTranslationKeys} from 'organization/Event/LocalizationConfig/TranslationForm'
import {useCallback} from 'react'

type TranslatedFields = Record<string, string>

export interface Localization {
  languages?: Language[]
  defaultLanguage?: Language['name'] | null
  translations?: {
    [P in Language['name']]?: TranslatedFields | null
  }
  translationsEnabled?: boolean
  isAdvancedMode?: boolean
}

export type Translations = NonNullable<Localization['translations']>

export function useUserTranslations() {
  const {current: language, defaultLanguage} = useLanguage()

  return useHandleText({
    language,
    defaultLanguage,
  })
}

export function useGuestTranslations() {
  const template = useTemplate()
  const language = template.localization?.defaultLanguage || ENGLISH

  return useHandleText({
    language,
    defaultLanguage: language,
  })
}

function useTranslate() {
  const template = useTemplate()
  const translationKeys = useAllTranslationKeys()

  return useCallback(
    ({
      text,
      target,
      fallback,
    }: {
      text: string
      target: Language['name']
      fallback?: Language['name']
    }) => {
      let result = text

      const keys = parseVariables(text)

      for (const key of keys) {
        const translation = findTranslation({
          key,
          target,
          fallback,
          translations: template.localization?.translations,
        })

        if (translation.value) {
          result = replace(key, translation.value, result)
          continue
        }

        // If any translation key exists, we'll replace it with blank text, and let
        // the user translate it at a later time.
        const isTranslationKey = translationKeys.includes(key)
        if (isTranslationKey) {
          result = replace(key, '', result)
          continue
        }
      }

      return result
    },
    [template, translationKeys],
  )
}

function findTranslation({
  key,
  target,
  fallback,
  translations,
}: {
  key: string
  target: Language['name']
  translations?: Translations
  fallback?: Language['name']
}): {
  value: string | null
} {
  /**
   * Translations field hasn't been initialized, no
   * translations have been defined. We'll just
   * check if it's a system key.
   */

  if (!translations) {
    return {
      value: systemDefault(key),
    }
  }

  /**
   * Try to find translations for the intended target (language).
   * If we fail to find one here, we'll try use the fallback
   * language if one is defined.
   */

  const language = translations[target]
  if (!language && fallback) {
    return findTranslation({key, target: fallback, translations})
  }

  if (!language) {
    return {
      value: systemDefault(key),
    }
  }

  /**
   * Finally we see if the language has the key we're looking for, if not
   * we'll try it in the fallback language.
   */

  const value = language[key]

  if (!value && fallback) {
    return findTranslation({key, target: fallback, translations})
  }

  if (!value) {
    return {
      value: systemDefault(key),
    }
  }

  /**
   * Found translation!
   */

  return {
    value,
  }
}

function useHandleText(options: {
  defaultLanguage: string
  language: string | null
}) {
  const translate = useTranslate()
  const {defaultLanguage, language} = options

  return useCallback(
    (text: string) => {
      if (!text) {
        return text
      }

      /**
       * If user (attendee) doesn't have a preferred language set, lets
       * just return the default language translation
       */

      if (!language) {
        return translate({text, target: defaultLanguage})
      }

      return translate({text, target: language, fallback: defaultLanguage})
    },
    [language, translate, defaultLanguage],
  )
}
