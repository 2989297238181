import {TicketRibbonProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbon'
import React, {useEffect, useState, useCallback} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm, UseFormMethods} from 'react-hook-form'
import {useDeleteCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload/UploadedTicketRibbon'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/TicketRibbonList/TicketRibbonConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {usePruneCustomRibbon} from 'organization/Event/DashboardConfig/TicketRibbonUpload'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {generateHashId} from 'lib/crypto/hash'

export interface TicketRibbonConfigProps {
  control: UseFormMethods['control']
  customRibbon?: TicketRibbonProps['customRibbon']
  setCustomRibbon: (customRibbon: TicketRibbonProps['customRibbon']) => void
  processing: boolean
  setProcessing: (procesing: boolean) => void
  ticketRibbon: TicketRibbonProps
}

export function TicketRibbonConfig(
  props: ComponentConfigProps & {
    ticketRibbon: TicketRibbonProps
    id?: string
  },
) {
  const {id, showing, onClose, ticketRibbon} = props
  const [processing, setProcessing] = useState(false)
  const deleteCustomRibbon = useDeleteCustomRibbon()
  const {
    control,
    handleSubmit,
    register,
    watch,
    formState,
    setValue,
  } = useForm()
  const {update: updateItem} = useEditSidebarItem()
  const pruneCustomRibbon = usePruneCustomRibbon()

  const [rules, setRules] = useState(ticketRibbon.rules || [])
  const [customRibbon, setCustomRibbon] = useState(ticketRibbon.customRibbon)

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(ticketRibbon.rules || [])
    setCustomRibbon(ticketRibbon.customRibbon)
  }, [showing, ticketRibbon])

  const update = (id: string, updated: TicketRibbonProps) => {
    updateItem({
      ribbons: {
        [id]: updated,
      },
    })

    pruneCustomRibbon({
      current: updated.customRibbon,
      saved: ticketRibbon.customRibbon,
    })
  }

  const insert = (newRibbon: TicketRibbonProps) => {
    generateHashId([
      'cards_',
      'ticket_ribbon',
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      updateItem({
        ribbons: {
          [id]: newRibbon,
        },
      })
    })
  }

  const save = (formData: any) => {
    const ribbon: TicketRibbonProps = {
      ...formData,
      rules,
      customRibbon,
    }

    if (id !== undefined) {
      update(id, ribbon)
      onClose()
      return
    }

    insert(ribbon)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error('Missing index for ticket ribbon')
    }

    if (ticketRibbon.customRibbon) {
      deleteCustomRibbon(ticketRibbon.customRibbon).catch(() => {
        /**
         * Ignore - if a model/image has already been removed
         * on server due to an aborted request, this will
         * fail. So we'll just ignore it, and proceed.
         */
      })
    }

    updateItem({
      ribbons: {
        [id]: REMOVE,
      },
    })

    onClose()
  }, [updateItem, onClose, deleteCustomRibbon, id, ticketRibbon])

  useAutoUpdateSidebarItem({
    item: id
      ? {
          ribbons: {
            [id]: {...watch(), customRibbon},
          },
        }
      : {},
    when: showing && Boolean(id),
  })

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Ticket Ribbon"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          customRibbon={customRibbon}
          setCustomRibbon={setCustomRibbon}
          ticketRibbon={ticketRibbon}
          processing={processing}
          setProcessing={setProcessing}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={ticketRibbon}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          aria-label="remove ticket ribbon"
          onClick={remove}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
