import React from 'react'
import {useHistory} from 'react-router-dom'
import {DragDropContext, Droppable, DropResult} from 'react-beautiful-dnd'
import styled from 'styled-components'
import Button from 'lib/ui/Button'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import Header from 'organization/Obie/Blocks/ProcessForm/Header'
import Page from 'organization/Obie/Blocks/ProcessForm/Page'
import AddSessionButton from 'organization/Obie/PagBuilder/AddSessionButton'
import SessionCard from 'organization/Obie/PagBuilder/SessionCard'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {useObieService} from 'organization/Obie/ObieServiceProvider'
import {usePag} from 'organization/Obie/PagProvider'
import {useOrganization} from 'organization/OrganizationProvider'
import TextField from 'lib/ui/TextField'
import {useDebounce} from 'react-use'
import {usePagRouteParams} from 'organization/Obie/PagRoutes'

/**
 * How long to wait before autosaving the PAG Builder after a change is made.
 */
const AUTOSAVE_DEBOUNCE_DELAY_MS = 500

export default function PagBuilder() {
  const {
    addSession,
    categoryId,
    deletePagBuilder,
    moveSession,
    name,
    setName,
    pagId,
    sessions,
    loading,
    updatePagBuilder,
  } = usePag()
  const {profileId} = useObieService()
  const history = useHistory()
  const {routes} = useOrganization()
  usePagRouteParams()

  const categoryRoute = profileRoute(
    routes,
    String(categoryId),
    String(profileId),
  ).root

  useDebounce(
    () => {
      if (loading) {
        return
      }

      updatePagBuilder(pagId, name)
    },
    AUTOSAVE_DEBOUNCE_DELAY_MS,
    [name, loading],
  )

  if (loading) {
    return null
  }

  if (!sessions.length) {
    const handleClick = () => {
      history.push(routes.obie.root)
    }

    return (
      <DarkThemeProvider>
        <Page>
          <Header />
          <Content>
            <Title>PAG Builder Could Not Be Found</Title>
            <StyledButton onClick={handleClick}>Back</StyledButton>
          </Content>
        </Page>
      </DarkThemeProvider>
    )
  }

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return

    const {source, destination} = result
    const sourceDayIndex = parseInt(source.droppableId)
    const targetDayIndex = parseInt(destination.droppableId)

    // Prevent dragging to first session of first day as that is locked
    // as the 'welcome' session
    if (targetDayIndex === 0 && destination.index === 0) {
      return
    }

    // Prevent dragging to last session of last day as that is locked
    // as the 'closing' session
    if (
      targetDayIndex === sessions.length - 1 &&
      destination.index === sessions[targetDayIndex].length - 1
    ) {
      return
    }

    moveSession({
      sourceDayIndex,
      sourceSessionIndex: source.index,
      destinationDayIndex: targetDayIndex,
      destinationSessionIndex: destination.index,
    })
  }

  const handleDelete = () => {
    deletePagBuilder(pagId).then(() => history.push(categoryRoute))
  }

  if (!sessions.length) {
    return null
  }

  return (
    <DarkThemeProvider>
      <Page>
        <Header onBack={() => history.push(categoryRoute)} />
        <Content>
          <SectionContainer>
            <SectionTitle>Event Name</SectionTitle>
            <StyledTextField
              value={name}
              onChange={(value) => setName(value)}
              className="bg-[#252b3d] border-dashed border-2 border-gray-500 text-white p-4"
              placeholder="Enter PAG name"
            />
          </SectionContainer>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Days>
              {sessions.map((day, dayIndex) => (
                <DayColumn key={dayIndex}>
                  <DayTitle>Day {dayIndex + 1}</DayTitle>
                  <Droppable droppableId={dayIndex.toString()}>
                    {(provided) => (
                      <SessionList
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {day.map((session, sessionIndex) => (
                          <SessionCard
                            key={`${dayIndex}-${sessionIndex}`}
                            pagId={pagId}
                            profileId={profileId}
                            session={session}
                            dayIndex={dayIndex}
                            sessionIndex={sessionIndex}
                            isDragDisabled={
                              // Disable first (welcome) session
                              (dayIndex === 0 && sessionIndex === 0) ||
                              // Disable last (closing) session
                              (dayIndex === sessions.length - 1 &&
                                sessionIndex === sessions[dayIndex].length - 1)
                            }
                          />
                        ))}
                        {provided.placeholder}
                      </SessionList>
                    )}
                  </Droppable>
                  <AddSessionButton
                    dayIndex={dayIndex}
                    onAddSession={(session) => addSession(dayIndex, session)}
                  />
                </DayColumn>
              ))}
            </Days>
          </DragDropContext>

          <ConfirmDialog
            title="Delete Session?"
            description="Are you sure you want to delete this PAG Builder?"
            confirmLabel="Delete"
            onConfirm={handleDelete}
          >
            {(showConfirmation) => (
              <DeleteButton
                size="large"
                fullWidth
                color="danger"
                variant="outlined"
                onClick={showConfirmation}
              >
                Delete
              </DeleteButton>
            )}
          </ConfirmDialog>
        </Content>
      </Page>
    </DarkThemeProvider>
  )
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  margin-bottom: 4rem;
`

const SectionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`

const StyledTextField = styled(TextField)`
  input {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`
const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
`

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`

const Days = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding-left: ${(props) => props.theme.spacing[6]};
    padding-right: ${(props) => props.theme.spacing[6]};
  }
`

const DayColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const DayTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.5rem;
`

const SessionList = styled.div`
  display: flex;
  flex-direction: column;
`

export const DeleteButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.25rem 0;
  width: 100%;

  &:hover {
    background: ${(props) => props.theme.colors.error} !important;
    color: #ffffff;
  }
`
