import {
  FormSubmission,
  useFormSubmissions,
} from 'lib/event-api/forms/use-form-submissions'
import {useSetSubmissions} from 'lib/event-api/forms/use-set-submissions'
import {FormSubmissionStatus} from 'organization/Event/Form/FormModeration/Question'
import {Form} from 'organization/Event/FormsProvider'
import {useCallback} from 'react'

interface UseMoveSubmissionParams {
  form: Form
}

export interface UseMoveSubmissionData {
  fromStatus: FormSubmissionStatus
  fromIndex: number
  toStatus: FormSubmissionStatus
  toIndex: number
}

export function useMoveSubmission(params: UseMoveSubmissionParams) {
  const {form} = params

  const {data: incomingSubmissions = []} = useFormSubmissions({
    form,
    status: 'incoming',
  })
  const {data: approvedSubmissions = []} = useFormSubmissions({
    form,
    status: 'approved',
  })
  const {data: onDeckSubmissions = []} = useFormSubmissions({
    form,
    status: 'on_deck',
  })

  const {data: answeredSubmissions = []} = useFormSubmissions({
    form,
    status: 'answered',
  })

  const {data: deletedSubmissions = []} = useFormSubmissions({
    form,
    status: 'deleted',
  })

  const {mutate} = useSetSubmissions({form})

  return useCallback(
    (data: UseMoveSubmissionData) => {
      const submissions: Record<FormSubmissionStatus, FormSubmission[]> = {
        incoming: incomingSubmissions,
        approved: approvedSubmissions,
        on_deck: onDeckSubmissions,
        answered: answeredSubmissions,
        deleted: deletedSubmissions,
      }

      if (data.fromStatus === data.toStatus) {
        const moved = Array.from(submissions[data.fromStatus])
        const toIndex = data.toIndex === -1 ? moved.length : data.toIndex
        const [removed] = moved.splice(data.fromIndex, 1)
        moved.splice(toIndex, 0, removed)
        const updatedMovedPositions = moved.map((submission, index) => ({
          ...submission,
          position: index + 1,
        }))
        mutate({status: data.toStatus, submissions: updatedMovedPositions})
        return
      }

      const source = Array.from(submissions[data.fromStatus])
      const [removed] = source.splice(data.fromIndex, 1)
      const updatedSourcePositions = source.map((submission, index) => ({
        ...submission,
        position: index + 1,
      }))

      const target = Array.from(submissions[data.toStatus])
      const toIndex = data.toIndex === -1 ? target.length : data.toIndex
      target.splice(toIndex, 0, {...removed, status: data.toStatus})
      const updatedTargetPositions = target.map((submission, index) => ({
        ...submission,
        position: index + 1,
      }))

      mutate({status: data.fromStatus, submissions: updatedSourcePositions})
      mutate({status: data.toStatus, submissions: updatedTargetPositions})
    },
    [
      incomingSubmissions,
      approvedSubmissions,
      onDeckSubmissions,
      answeredSubmissions,
      deletedSubmissions,
      mutate,
    ],
  )
}
