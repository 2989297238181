import {useTemplate} from 'Event/TemplateProvider'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import ColorPicker from 'lib/ui/ColorPicker'
import TextField from 'lib/ui/TextField'
import {useConfig} from 'organization/Event/Configurable'
import Config from 'organization/Event/Configurable/Config'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import React from 'react'
import {Controller} from 'react-hook-form'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAttendeeVariables} from 'Event'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function SignatureBoxConfig() {
  const {
    form: {register, control},
  } = useConfig()
  const save = useSaveTemplate()
  const {waiver: settings} = useTemplate()
  const addTranslation = useAddTranslation()

  const v = useAttendeeVariables()

  const handleSave = (data: any) => {
    const labelText = replaceAtPath(
      data,
      'waiver.signature.typeInputLabel',
      '{{waiver_signature_typeInputLabel}}',
    )

    const buttonText = replaceAtPath(
      data,
      'waiver.signature.typeButtonText',
      '{{waiver_signature_typeButtonText}}',
    )
    save({
      ...data,
      localization: addTranslation({
        waiver_signature_typeInputLabel: labelText ?? '',
        waiver_signature_typeButtonText: buttonText ?? '',
      }),
    })
  }

  return (
    <Config title="Signature" onSave={handleSave}>
      <SettingsPanel>
        <TextField
          name="waiver.signature.typeButtonText"
          defaultValue={v(settings.signature.typeButtonText)}
          label="Type Button Text"
          fullWidth
          inputProps={{
            'aria-label': 'signature type button text',
            ref: register,
          }}
        />
        <TextField
          name="waiver.signature.typeInputLabel"
          defaultValue={v(settings.signature.typeInputLabel)}
          label="Input Label Text"
          fullWidth
          inputProps={{
            'aria-label': 'signature input label text',
            ref: register,
          }}
        />
      </SettingsPanel>
      <StylingPanel>
        <Controller
          name="waiver.signature.typeButtonColor"
          defaultValue={settings.signature.typeButtonColor}
          control={control}
          render={({value, onChange}) => (
            <ColorPicker
              label="Type Button Color"
              color={value}
              onPick={onChange}
              aria-label="signature type button color"
            />
          )}
        />
      </StylingPanel>
    </Config>
  )
}
