import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'
import NavButton, {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React from 'react'
import {
  Draggable,
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd'
import {useEditMode} from 'Event/EditModeProvider'
import {useTemplate} from 'Event/TemplateProvider'
import Configurable from 'organization/Event/Configurable'
import CustomButtonConfig from 'Event/Step3/TechCheck/Buttons/CustomButtons/CustomButtonConfig'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {ConfirmedButtonContext} from 'Event/Dashboard/components/NavButton/ConfirmedButton'
import {generateHashId} from 'lib/crypto/hash'

type CustomButtonProps = {
  id: string
  button: NavButtonWithSize
  index: number
}

export default React.memo((props: CustomButtonProps) => {
  const {id, index} = props
  const isEditMode = useEditMode()

  if (!isEditMode) {
    return (
      <Container button={props.button}>
        <Button {...props} />
      </Container>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <Container
          button={props.button}
          ref={provided.innerRef}
          draggableProps={provided.draggableProps}
        >
          <ConfigurableButton
            {...props}
            dragHandleProps={provided.dragHandleProps}
          />
        </Container>
      )}
    </Draggable>
  )
})

function ConfigurableButton(
  props: CustomButtonProps & {
    dragHandleProps?: DraggableProvidedDragHandleProps
  },
) {
  const {dragHandleProps} = props

  const saveTemplate = useSaveTemplate()

  const copy = () => {
    generateHashId([
      'tech_check',
      'custom_button_',
      Date.now().toString(),
      Math.random().toString(),
    ]).then((id) => {
      saveTemplate({
        techCheck: {
          customButtons: {
            [id]: props.button,
          },
        },
      })
    })
  }

  return (
    <Configurable
      editableProps={{
        onCopy: copy,
        handleProps: dragHandleProps,
      }}
    >
      <CustomButtonConfig id={props.id} button={props.button} />
      <ConfirmedButtonContext.Provider
        value={{
          key: `techCheck.customButtons.${props.id}`,
          button: props.button,
        }}
      >
        <Button {...props} />
      </ConfirmedButtonContext.Provider>
    </Configurable>
  )
}

function Button(props: CustomButtonProps) {
  const {
    techCheck: {areaKey},
  } = useTemplate()

  /**
   * Since we only ever want to allow TC buttons to join the TC assigned area,
   * we'll automatically set it here. If the button is marked as
   * `isAreaButton`, then this area will be joined.
   */
  const areaId = areaKey || null

  const buttonProps: NavButtonProps = {
    ...props.button,
    areaId,
  }

  return <NavButton {...buttonProps} aria-label="tech check button" />
}

const Container = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactElement
    button: NavButtonWithSize
    draggableProps?: DraggableProvidedDraggableProps
  }
>((props, ref) => {
  const lineBreak = props.button.newLine ? <SpacerGrid item xs={12} /> : null

  return (
    <>
      {lineBreak}
      <Grid
        item
        xs={12}
        md={props.button.size}
        ref={ref}
        {...props.draggableProps}
      >
        {props.children}
      </Grid>
    </>
  )
})

const SpacerGrid = styled(Grid)`
  padding: 0 !important;
`
