import ColorPicker from 'lib/ui/ColorPicker'
import React from 'react'
import {useCardsTemplate} from 'Event/template/Cards'
import Slider from 'lib/ui/Slider'
import TextField from 'lib/ui/TextField'
import {Label} from 'lib/ui/typography'
import {Controller, UseFormMethods} from 'react-hook-form'
import Title from 'lib/ui/ConfigPanel/Title'
import Divider from 'lib/ui/ConfigPanel/Divider'
import {useAttendeeVariables} from 'Event'

const MIN_MAIN_NAV_WIDTH = 10
const MAX_MAIN_NAV_WIDTH = 100

const MIN_MAIN_NAV_BUTTON_HEIGHT = 30
const MAX_MAIN_NAV_BUTTON_HEIGHT = 300

const MIN_MAIN_NAV_BORDER_RADIUS = 0
const MAX_MAIN_NAV_BORDER_RADIUS = 100

export default function Settings(props: {control: UseFormMethods['control']}) {
  const {control} = props
  const {mainNav} = useCardsTemplate()
  const v = useAttendeeVariables()

  return (
    <>
      <Controller
        name="width"
        defaultValue={mainNav.width}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_MAIN_NAV_WIDTH}
            max={MAX_MAIN_NAV_WIDTH}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="main nav container width"
            label="Width"
          />
        )}
      />
      <Controller
        name="buttonHeight"
        defaultValue={mainNav.buttonHeight}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_MAIN_NAV_BUTTON_HEIGHT}
            max={MAX_MAIN_NAV_BUTTON_HEIGHT}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="main nav button height"
            label="Button Height"
          />
        )}
      />
      <Controller
        name="borderRadius"
        defaultValue={mainNav.borderRadius}
        control={control}
        render={({value, onChange}) => (
          <Slider
            min={MIN_MAIN_NAV_BORDER_RADIUS}
            max={MAX_MAIN_NAV_BORDER_RADIUS}
            step={1}
            onChange={onChange}
            valueLabelDisplay="auto"
            value={value}
            aria-label="main nav container border radius"
            label="Border Radius"
          />
        )}
      />
      <Divider />
      <Title>Scroll Down</Title>
      <Label>Text</Label>
      <Controller
        name="scrollDownText"
        control={control}
        defaultValue={v(mainNav.scrollDownText)}
        render={({value, onChange}) => (
          <TextField
            aria-label="scroll down text"
            fullWidth
            onChange={onChange}
            value={value}
          />
        )}
      />
      <Controller
        name="scrollDownArrowColor"
        defaultValue={mainNav.scrollDownArrowColor}
        control={control}
        render={({value, onChange}) => (
          <ColorPicker
            label="Arrow Color"
            color={value}
            onPick={onChange}
            aria-label="scroll down arrow color"
          />
        )}
      />
    </>
  )
}
