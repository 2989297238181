import {AnswerSet} from 'organization/Obie/ObieServiceProvider'

export const TYPE_WELCOME = 'welcome'
export const TYPE_OPENING = 'opening'
export const TYPE_HOT_CONTENT = 'hot_content'
export const TYPE_PANEL_OFFER = 'panel_offer'
export const TYPE_LASER_COACHING = 'laser_coaching'
export const TYPE_REPITCH = 'repitch'
export const TYPE_CLOSING = 'closing'
export const TYPE_CONTENT = 'content'
export const TYPE_BREAK = 'break'
export const TYPE_BONUS = 'bonus'
export const TYPE_DEFAULT = TYPE_CONTENT

export const SESSION_TYPES = {
  [TYPE_WELCOME]: 'Welcome Session',
  [TYPE_OPENING]: 'Opening Session',
  [TYPE_HOT_CONTENT]: 'Hot Content',
  [TYPE_PANEL_OFFER]: 'Panel Offer',
  [TYPE_LASER_COACHING]: 'Laser Coaching',
  [TYPE_REPITCH]: 'Repitch',
  [TYPE_CLOSING]: 'Closing Session',
  [TYPE_CONTENT]: 'Content',
  [TYPE_BREAK]: 'Break',
  [TYPE_BONUS]: 'Bonus',
}

export type SessionType =
  | typeof TYPE_WELCOME
  | typeof TYPE_OPENING
  | typeof TYPE_HOT_CONTENT
  | typeof TYPE_PANEL_OFFER
  | typeof TYPE_LASER_COACHING
  | typeof TYPE_REPITCH
  | typeof TYPE_CLOSING
  | typeof TYPE_CONTENT
  | typeof TYPE_BREAK
  | typeof TYPE_BONUS

export interface ProgramAtAGlance {
  id: number
  name: string
  answer_set_id: number
  answer_set: AnswerSet
  sessions: Session[][]
}
export interface ProgramAtAGlanceSessions {
  sessions: Session[][]
}

export interface Session {
  type: SessionType
  id: number
  day: number
  sort: number
  name: string
  startTime?: string
  durationMins: number
  overview?: string
  details?: string
  rationale?: string
}
