import React from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {usePanelsUpdate} from 'Event/template/Panels'
import Settings from 'Event/template/Panels/Dashboard/Resources/ResourceList/ResourceListConfig/Settings'
import Styling from 'Event/template/Panels/Dashboard/Resources/ResourceList/ResourceListConfig/Styling'
import {ResourceListSettings} from 'Event/template/Panels/Dashboard/Resources/ResourceList'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export default function ResourceListConfig(props: ComponentConfigProps) {
  const {showing: isVisible, onClose} = props
  const {control, register, handleSubmit, formState} = useForm()
  const updateTemplate = usePanelsUpdate()
  const addTranslation = useAddTranslation()

  const submit = (
    data: Pick<
      ResourceListSettings,
      | 'isVisible'
      | 'title'
      | 'menuTitle'
      | 'cardBackgroundColor'
      | 'cardBackgroundOpacity'
      | 'color'
      | 'linkColor'
    >,
  ) => {
    const title = replaceAtPath(
      data,
      'resourceList.title',
      '{{resource_list_title}}',
    )
    const menuTitle = replaceAtPath(
      data,
      'resourceList.menuTitle',
      '{{resource_list_menu_title}}',
    )

    updateTemplate({
      resourceList: data,
      localization: addTranslation({
        resource_list_title: title ?? 'Resouces',
        resource_list_menu_title: menuTitle ?? 'Resouces',
      }),
    })

    onClose()
  }

  return (
    <ComponentConfig
      showing={isVisible}
      onClose={onClose}
      title="Resources"
      onSubmit={handleSubmit(submit)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
