import {useState} from 'react'
import styled from 'styled-components'
import Dialog from 'lib/ui/Dialog'
import Button from 'lib/ui/Button'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Select from 'lib/ui/Select'
import TextField from 'lib/ui/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Icon from 'lib/ui/Icon'
import {SessionType} from 'organization/Obie/PagBuilder/types'
import {Session} from 'organization/Obie/PagBuilder/types'

interface AddSessionDialogProps {
  onAddSession: (session: Session) => void
  dayIndex: number
}

export default function AddSessionDialog({
  onAddSession,
  dayIndex,
}: AddSessionDialogProps) {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState<SessionType | null>(null)
  const [name, setName] = useState('')
  const [duration, setDuration] = useState('')

  const handleSubmit = () => {
    if (!type) {
      return
    }

    onAddSession({
      id: 0,
      day: dayIndex,
      sort: 200,
      type,
      name,
      durationMins: parseInt(duration),
    })
    setOpen(false)
    setType(null)
    setName('')
    setDuration('')
  }

  return (
    <>
      <StyledButton variant="contained" onClick={() => setOpen(true)}>
        <Icon className="fas fa-plus" iconSize={36} color="#c8cfe0" />
      </StyledButton>

      <StyledDialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Session</DialogTitle>
        <DialogContent>
          <FormField>
            <Select
              fullWidth
              value={type || ''}
              onChange={(e) => setType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select type
              </MenuItem>
              <MenuItem value="hot_content">Hot Content</MenuItem>
              <MenuItem value="panel_offer">Panel Offer</MenuItem>
              <MenuItem value="laser_coaching">Laser Coaching</MenuItem>
              <MenuItem value="repitch">Repitch</MenuItem>
              <MenuItem value="content">Content</MenuItem>
              <MenuItem value="break">Break</MenuItem>
              <MenuItem value="bonus">Bonus</MenuItem>
            </Select>
          </FormField>

          {type !== 'break' && (
            <FormField>
              <TextField
                fullWidth
                label="Session Name"
                value={name}
                onChange={setName}
                placeholder="Enter session name"
              />
            </FormField>
          )}
          <FormField>
            <TextField
              fullWidth
              type="number"
              label="Duration (minutes)"
              value={duration}
              onChange={setDuration}
              placeholder="e.g., 90"
            />
          </FormField>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Add Session
          </Button>
        </DialogContent>
      </StyledDialog>
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 64px;
  background-color: #394f8c;
  border-radius: 0;

  &:hover {
    background-color: #2d3f70;
  }
`

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    color: ${({theme}) => theme.colors.text.primary};
  }
`

const FormField = styled.div`
  margin: 16px 0;
`
