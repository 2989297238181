import React from 'react'
import {Townhall, useTownhallUpdate} from 'Event/template/Townhall'
import {useForm} from 'react-hook-form'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {DeepPartial} from 'lib/type-utils'
import Settings from 'Event/template/Townhall/Speakers/SpeakersList/SpeakersListConfig/Settings'
import Styling from 'Event/template/Townhall/Speakers/SpeakersList/SpeakersListConfig/Styling'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import {useAutoUpdate} from 'organization/Event/TemplateEditor'
import {useAddTranslation} from 'Event/LanguageProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'

export default function SpeakersListConfig(props: ComponentConfigProps) {
  const {showing, onClose} = props
  const update = useTownhallUpdate()
  const {control, watch, handleSubmit, formState} = useForm()
  const addTranslation = useAddTranslation()

  const save = (data: DeepPartial<Townhall>) => {
    const title = replaceAtPath(
      data,
      'speakers.otherSpeakersTitle',
      '{{speakers_other_speakers_title}}',
    )

    const saveData = {
      ...data,
      localization: addTranslation({
        speakers_other_speakers_title: title || 'Check other speakers',
      }),
    }
    update(saveData)
    onClose()
  }

  useAutoUpdate({values: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Other Speakers List"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} />
      </StylingPanel>
      <Footer>
        <SaveButton type="submit" />
      </Footer>
    </ComponentConfig>
  )
}
