import React, {useEffect} from 'react'
import {Route, Switch, useParams} from 'react-router-dom'
import {routesWithValue} from 'lib/url'
import {
  OrganizationRoutes,
  useOrganization,
} from 'organization/OrganizationProvider'
import ObieLayout from 'organization/Obie/Layout'
import PagBuilder from 'organization/Obie/PagBuilder'
import PagProvider, {usePag} from 'organization/Obie/PagProvider'
import PagSessionDetails from 'organization/Obie/PagSessionDetails'
import {useObieService} from 'organization/Obie/ObieServiceProvider'

export function pagRoute(
  routes: OrganizationRoutes,
  pagId: number | string,
  profileId: number | string,
) {
  return routesWithValue(
    ':pag',
    String(pagId),
    routesWithValue(
      ':profile',
      String(profileId),
      routes.obie.pag[':pag'].profile[':profile'],
    ),
  ).root
}

export function sessionRoute(
  routes: OrganizationRoutes,
  pagId: number | string,
  profileId: number | string,
  dayIndex: number | string,
  sessionIndex: number | string,
) {
  return routesWithValue(
    ':pag',
    String(pagId),
    routesWithValue(
      ':profile',
      String(profileId),
      routesWithValue(
        ':day',
        String(dayIndex),
        routesWithValue(
          ':session',
          String(sessionIndex),
          routes.obie.pag[':pag'].profile[':profile'].day[':day'].session[
            ':session'
          ],
        ),
      ),
    ),
  )
}

export default function PagRoutes() {
  const {routes} = useOrganization()

  return (
    <ObieLayout hideSidebar>
      <PagProvider>
        <Switch>
          <Route
            path={
              routes.obie.pag[':pag'].profile[':profile'].day[':day'].session[
                ':session'
              ].root
            }
          >
            <PagSessionDetails />
          </Route>
          <Route path={routes.obie.pag[':pag'].profile[':profile'].root}>
            <PagBuilder />
          </Route>
        </Switch>
      </PagProvider>
    </ObieLayout>
  )
}

/**
 * Consistently gets PAG Builder parameters out of the route and into state of
 * the PAG Provider.
 *
 * @returns void
 */
export function usePagRouteParams() {
  const {setPagId, setDayIndex, setSessionIndex} = usePag()
  const {setProfileId} = useObieService()

  const route = useParams<{
    pag?: string
    profile?: string
    day?: string
    session?: string
  }>()

  useEffect(() => {
    const pagId = route.pag ? parseInt(route.pag) : null
    if (pagId) {
      setPagId(pagId)
    }

    const profileId = route.profile ? parseInt(route.profile) : null
    if (profileId !== null) {
      setProfileId(profileId)
    }

    const dayIndex = route.day ? parseInt(route.day) : null
    if (dayIndex) {
      setDayIndex(dayIndex)
    }

    const sessionIndex = route.session ? parseInt(route.session) : null
    if (sessionIndex) {
      setSessionIndex(sessionIndex)
    }
  }, [route, setPagId, setProfileId, setDayIndex, setSessionIndex])
}
