import {useEditMode} from 'Event/EditModeProvider'
import Typography from '@material-ui/core/Typography'
import React, {useEffect, useState} from 'react'
import {guardValidDimensions, guardValidMimeType} from 'lib/image'
import styled from 'styled-components'
import {spacing} from 'lib/ui/theme'
import withStyles from '@material-ui/core/styles/withStyles'
import {useBlocking} from 'lib/requests'
import {useUploadAsset} from 'lib/asset'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Cropper from 'lib/ui/form/ImageUpload/Cropper'
import UploadButton from 'lib/ui/form/ImageUpload/UploadButton'
import {generateHashId} from 'lib/crypto/hash'

const MAX_FILE_SIZE_BYTES = 10000000 // 10MB
const ASPECT_RATIO = 0.5625 // 16:9

export default function UploadBackgroundButton() {
  const isEditMode = useEditMode()
  if (!isEditMode) {
    return null
  }

  return <Content />
}

function Content() {
  const isEditMode = useEditMode()
  const [error, setError] = useState<null | string>(null)
  const [cropTarget, setCropTarget] = useState<File | null>(null)
  const [selected, setSelected] = useState<File | null>(null)
  const clearError = () => setError(null)
  const updateTemplate = useSaveTemplate()
  const uploadAsset = useUploadAsset()
  const {blocking, busy} = useBlocking()

  const upload = blocking((image: File) => {
    const formData = new FormData()
    formData.set('image', image)

    return uploadAsset(image).then((asset) => {
      generateHashId([
        'zoom_backgrounds_',
        Date.now().toString(),
        Math.random().toString(),
      ]).then((id) => {
        const backgroundId = `backgrounds_${id}`
        updateTemplate({
          zoomBackgrounds: {
            items: {
              [backgroundId]: {
                image: asset.file.url,
              },
            },
          },
        })
      })
    })
  })

  useEffect(() => {
    if (busy) {
      return
    }

    if (selected && error) {
      clearError()
    }

    if (!selected) {
      return
    }

    if (selected.size > MAX_FILE_SIZE_BYTES) {
      setError('Image too large. Please select an image smaller than 10MB.')
      return
    }

    guardValidMimeType(selected)
      .then(guardValidDimensions(ASPECT_RATIO))
      .then(upload)
      .catch(setError)

    setSelected(null)
  }, [error, selected, upload, busy, setSelected])

  if (!isEditMode) {
    return null
  }

  return (
    <Box>
      <ButtonHolder>
        <Cropper
          width={1280}
          height={720}
          image={cropTarget}
          isOpen={Boolean(cropTarget)}
          onCancel={() => {
            setCropTarget(null)
            setSelected(cropTarget)
          }}
          onCrop={(image) => {
            setCropTarget(null)
            setSelected(image)
          }}
        />
        <UploadButton
          disabled={busy}
          inputProps={{
            'aria-label': 'background upload',
          }}
          onSelect={setCropTarget}
          isVisible
        >
          Upload Zoom Background
        </UploadButton>
      </ButtonHolder>
      <Error>{error}</Error>
    </Box>
  )
}

function Error(props: {children: string | null}) {
  if (!props.children) {
    return null
  }

  return (
    <ErrorText color="error">
      We could not upload your file: {props.children}
    </ErrorText>
  )
}

const ErrorText = withStyles({
  root: {
    marginTop: spacing[3],
    marginBottom: spacing[3],
  },
})(Typography)

const ButtonHolder = styled.div`
  display: flex;
  justify-content: center;
`

const Box = styled.div`
  margin-bottom: ${(props) => props.theme.spacing[3]};
`
