import React from 'react'
import {Controller, useForm} from 'react-hook-form'
import styled from 'styled-components'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from 'lib/ui/Button'
import Dialog from 'lib/ui/Dialog'
import TextField from 'lib/ui/TextField'
import {
  DEFAULT_PROFILE_LABEL,
  Profile,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'

export default function RenameProfileDialog(props: {
  profileId?: number
  onClose: () => void
  onSubmit: (categoryId: number, profileId: number | undefined) => void
  open: boolean
}) {
  const {profileId, onClose, open} = props
  const {handleSubmit, control} = useForm()
  const {
    categoryId,
    deleteProfileAlias,
    getCategory,
    updateProfile,
  } = useObieService()

  if (!open) {
    return null
  }

  const category = getCategory(categoryId)

  const profile = (category.profiles || []).find(
    (profile) => profile.id === profileId,
  )

  const onSubmit = (formData: any) => {
    updateProfile(categoryId, profileId, formData.name).then(() => {
      onClose && onClose()
      props.onSubmit(categoryId, profileId)
    })
  }

  const onReset = () => {
    deleteProfileAlias(categoryId).then(() => {
      onClose && onClose()
      props.onSubmit(categoryId, profileId)
    })
  }

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>Rename {profile?.name}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue={profile?.name}
            render={({value, onChange}) => (
              <TextField
                fullWidth
                label="Profile Name"
                name="name"
                onChange={onChange}
                required
                type="text"
                value={value}
                variant="outlined"
              />
            )}
          />
          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
          <ResetButton profile={profile} onReset={onReset} />
        </form>
      </DialogContent>
    </Dialog>
  )
}

const ResetButton = (props: {onReset: () => void; profile?: Profile}) => {
  const {onReset, profile} = props

  if (
    profile?.id !== 0 ||
    (profile?.id === 0 && profile?.name === DEFAULT_PROFILE_LABEL)
  ) {
    return null
  }

  return (
    <StyledResetButton
      type="button"
      variant="contained"
      color="danger"
      onClick={onReset}
    >
      Reset Default Profile
    </StyledResetButton>
  )
}

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`

const StyledResetButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-left: ${(props) => props.theme.spacing[8]};
`
