import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import LinkUI from '@ckeditor/ckeditor5-link/src/linkui';
import '../theme/style.css';
import { LinkCommand } from '@ckeditor/ckeditor5-link';
import LinkFormView from '@ckeditor/ckeditor5-link/src/ui/linkformview';

export default class LeaderboardLink extends Plugin {

	private linkFormView : LinkFormView | null = null;

    static get requires() {
        return [LinkUI];
    }

    init() {
        const editor = this.editor;
        const rexlinkConfig: any = this.editor.config.get('links.rexlink');
		const linkUI = editor.plugins.get(LinkUI);

		const contextualBalloonPlugin = editor.plugins.get( 'ContextualBalloon' );

		this.listenTo( contextualBalloonPlugin, 'change:visibleView', ( evt, name, visibleView ) => {
			if ( visibleView === linkUI.formView && rexlinkConfig && rexlinkConfig.length === 1) {
				// Detach the listener.
				this.stopListening( contextualBalloonPlugin, 'change:visibleView' );

				this.linkFormView = linkUI.formView!;
                const button = this._createButton(rexlinkConfig[0], this.getValue(rexlinkConfig[0]));

				// Render button's tamplate.
				button.render();

				// Register the button under the link form view, it will handle its destruction.
				this.linkFormView.registerChild( button );

				// Inject the element into DOM.
				this.linkFormView.element?.insertBefore( button.element!, this.linkFormView.saveButtonView.element );
			}
		} );

    }

    _createButton(label: string, value: string) {
        const editor = this.editor;
        const button = new ButtonView(editor.locale);
        const linkCommand: LinkCommand = editor.commands.get('link')!;
        const that = this;

        button.set({
            label,
            withText: true,
            tooltip: true
        });

        button.extendTemplate({
            attributes: {
                class: ['obvio-leaderboard-link']
            }
        });

        // Probably this button should be also disabled when the link command is disabled
        // Try setting editor.isReadOnly = true to see it in action
        button.bind('isEnabled').to(linkCommand);

        button.on('execute', () => {
            // Do something (like open the popup), then update the link URL field's value
            // The line below will be probably executed inside some callback
            const linkUrl = value;
            that.linkFormView!.urlInputView.fieldView.element!.value = linkUrl;
        });

        return button;
    }

    getValue(label : string): string {
        if (label == 'Leaderboard') {
            return '/leaderboard';
        }

        if (label == 'Login') {
            return '{{login_url}}';
        }

        return '';
    }
}
