import {
  isQuestionWithOptions as hasOptions,
  Question,
  useQuestions,
} from 'organization/Event/QuestionsProvider'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {v4 as uuid} from 'uuid'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Settings from './Settings'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import {Rule} from 'Event/attendee-rules'
import {Form} from 'organization/Event/FormsProvider'

export default function QuestionConfig(
  props: ComponentConfigProps & {
    question?: Question
    form: Form
  },
) {
  const {question, onClose, showing} = props
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    setError,
    formState: {isDirty},
  } = useForm({
    defaultValues: {
      options: hasOptions(question) ? question.options : [],
      type: question?.type || '',
    },
  })
  const [rules, setRules] = useState(question?.rules)

  useEffect(() => {
    if (!question) {
      return
    }

    setValue('type', question.type)
    setValue('is_required', question.is_required)
  }, [question, setValue])

  const questions = useQuestions()

  const save = (data: Question) => {
    const newQuesion = fill(data, question, rules)

    const hasTypeSelected = !!data.type

    if (!hasTypeSelected) {
      // MUI can't handle requred for select, so that have to be added manually
      setError('type', {type: 'manual', message: 'Type is required'})
      return
    }

    if (props.question) {
      questions.update(newQuesion)
    } else {
      questions.add(newQuesion)
    }
    props.onClose()
  }

  const remove = () => {
    if (!question) {
      return
    }
    questions.remove(question)
    props.onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Form Question Config"
      onSubmit={handleSubmit(save)}
      hasChanges={isDirty}
      isDialogModal
    >
      <SettingsPanel>
        <Settings
          question={question}
          watch={watch}
          register={register}
          control={control}
          errors={errors}
        />
      </SettingsPanel>
      <RulesPanel
        rules={rules}
        setRules={setRules}
        configProps={{
          form: {...props.form, questions: questions.questions},
          description: 'Show question when',
        }}
      />
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          showing={Boolean(question)}
          fullWidth
          variant="outlined"
          aria-label="remove question"
          onClick={remove}
          disabled={!question}
        />
      </Footer>
    </ComponentConfig>
  )
}

function fill<T extends Question>(
  question: T,
  saved?: Question,
  rules?: Rule[],
) {
  const result: T = {
    ...question,
    // If we're adding a new question, let's give it a temp uuid
    id: saved ? saved.id : uuid(),
    rules: rules,
  }

  // A question may have changed from a type that did not have
  // options to one that did. If it had, we should send
  // over an empty array if it hasn't been set.
  if (hasOptions(result)) {
    result.options = result.options || []
  }

  return result
}
