import React, {useState} from 'react'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {AgendaListProps} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import {useAutoUpdateSidebarItem} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {Rule} from 'Event/attendee-rules'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Settings'
import {useForm} from 'react-hook-form'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import Styling from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaListConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import {useSaveTemplate} from 'Event/TemplateUpdateProvider'
import {replaceAtPath} from 'lib/JsonUpdateProvider'
import {useAddTranslation} from 'Event/LanguageProvider'

export function AgendaListConfig(
  props: ComponentConfigProps & {
    list: AgendaListProps
    id: string
  },
) {
  const {showing, onClose, list, id} = props
  const [rules, setRules] = useState<Rule[]>(list.rules || [])
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState,
    setValue,
  } = useForm()
  const saveTemplate = useSaveTemplate()
  const addTranslation = useAddTranslation()

  const save = (data: AgendaListProps) => {
    const title = replaceAtPath(data, 'title', `{{${id}_title}}`)
    const description = replaceAtPath(
      data,
      'description',
      `{{${id}_description}}`,
    )
    const footer = replaceAtPath(data, 'footer', `{{${id}_footer}}`)
    saveTemplate({
      sidebarItems: {[id]: {...data, rules}},
      localization: addTranslation({
        [`${id}_title`]: title ?? '',
        [`${id}_description`]: description ?? '',
        [`${id}_footer`]: footer ?? '',
      }),
    })
    onClose()
  }

  useAutoUpdateSidebarItem({item: watch(), when: showing})

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agendas"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} register={register} list={list} />
      </SettingsPanel>
      <StylingPanel>
        <Styling control={control} list={list} />
      </StylingPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput setValue={setValue} control={control} values={list} />
      </RulesPanel>
      <Footer>
        <SaveButton />
      </Footer>
    </ComponentConfig>
  )
}
