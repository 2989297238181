import React, {useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import styled from 'styled-components'
import {onChangeDate, onUnknownChangeHandler} from 'lib/dom'
import {useDebouncedHandler} from 'lib/debounce'
import Button from 'lib/ui/Button'
import ConfirmDialog from 'lib/ui/ConfirmDialog'
import MarkdownEditor from 'lib/ui/form/TextEditor/MarkdownEditor'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import TextField, {Box} from 'lib/ui/TextField'
import {DarkThemeProvider} from 'lib/ui/theme/ThemeProvider'
import Header from 'organization/Obie/Blocks/ProcessForm/Header'
import Page from 'organization/Obie/Blocks/ProcessForm/Page'
import {
  Session,
  SESSION_TYPES,
  SessionType,
  TYPE_BREAK,
  TYPE_CLOSING,
  TYPE_DEFAULT,
  TYPE_WELCOME,
} from 'organization/Obie/PagBuilder/types'
import {usePag} from 'organization/Obie/PagProvider'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {useObieService} from 'organization/Obie/ObieServiceProvider'
import {pagRoute, usePagRouteParams} from 'organization/Obie/PagRoutes'
import {useOrganization} from 'organization/OrganizationProvider'
import LocalizedDateTimePicker from 'lib/LocalizedDateTimePicker'

export default function PagSessionDetails() {
  const DEBOUNCE_DELAY_MS = 500

  const {deleteSession, updateSession, pagId, loading} = usePag()
  const {
    blocks,
    categoryId,
    fetchBlocks,
    loadingBlocks,
    profileId,
  } = useObieService()

  const history = useHistory()
  const {routes} = useOrganization()
  const session = useRouteSession()
  const pRoute = pagRoute(routes, pagId, profileId || 0)

  usePagRouteParams()

  const saveSession = (): Promise<Session> | undefined => {
    // If our local state hasn't been initialized yet, get out of here.
    if (!session) {
      return
    }

    return updateSession(
      session.id,
      type,
      duration,
      name,
      startTime,
      overview,
      details,
    )
  }

  // If we're not already loading blocks, but we don't have any blocks in state,
  // suggests that user has reloaded the PAG Session Details page, or landed here
  // from a link. In this case, we need to fetch the blocks, so we can do work.
  useEffect(() => {
    if (!loadingBlocks && !blocks.length) {
      fetchBlocks(categoryId, profileId)
    }
  }, [blocks, categoryId, fetchBlocks, loadingBlocks, profileId])

  const [name, setName] = useDebouncedHandler(
    session?.name || '',
    saveSession,
    DEBOUNCE_DELAY_MS,
  )
  const [startTime, setStartTime] = useDebouncedHandler(
    session?.startTime || '',
    saveSession,
    DEBOUNCE_DELAY_MS,
  )
  const [type, setType] = useDebouncedHandler(
    session?.type || TYPE_DEFAULT,
    saveSession,
    DEBOUNCE_DELAY_MS,
  )
  const [duration, setDuration] = useDebouncedHandler(
    session?.durationMins || 0,
    saveSession,
    DEBOUNCE_DELAY_MS,
  )
  const [overview, setOverview] = useDebouncedHandler(
    session?.overview || '',
    saveSession,
    DEBOUNCE_DELAY_MS,
  )
  const [details, setDetails] = useDebouncedHandler(
    session?.details || '',
    saveSession,
    DEBOUNCE_DELAY_MS,
  )

  const {session: sessionIndex} = useParams<{
    day: string
    session: string
  }>()

  const isFirstSession = sessionIndex === '0'

  if (loading) {
    return null
  }

  if (!session) {
    const handleClick = () => {
      return history.push(pRoute)
    }

    return (
      <Page>
        <Header />
        <Content>
          <Title>Session Could Not Be Found</Title>
          <StyledButton onClick={handleClick}>Back</StyledButton>
        </Content>
      </Page>
    )
  }

  const handleDelete = () => {
    deleteSession(session).then(() => history.push(pRoute))
  }

  return (
    <Page>
      <Header onBack={() => history.push(pRoute)} />
      <Content>
        <Title>Session Details</Title>
        <SectionContainer>
          <TypeEditorContainer>
            <TypeLabel>Session Type:</TypeLabel>
            <StyledSelect
              fullWidth
              value={type || ''}
              onChange={onUnknownChangeHandler(setType)}
              displayEmpty
            >
              <Option value="" disabled>
                Select type
              </Option>
              {Object.entries(SESSION_TYPES).map(([value, label], key) => {
                return (
                  <Option key={key} value={value}>
                    {label}
                  </Option>
                )
              })}
            </StyledSelect>
          </TypeEditorContainer>
          {isFirstSession && (
            <TypeEditorContainer>
              <TypeLabel>Session Start Time:</TypeLabel>
              <LocalizedDateTimePicker
                clearable
                value={startTime}
                onChange={onChangeDate(setStartTime)}
                fullWidth
              />
            </TypeEditorContainer>
          )}
          <TypeEditorContainer>
            <TypeLabel>Session Duration:</TypeLabel>
            <TextField
              fullWidth
              type="number"
              label="Duration (minutes)"
              value={duration.toString()}
              onChange={(value) => setDuration(parseInt(value))}
              placeholder="e.g., 90"
            />
          </TypeEditorContainer>
        </SectionContainer>

        {/* Session Name */}
        <SectionContainer>
          <SectionTitle>Session Name</SectionTitle>
          <StyledTextField
            value={name}
            onChange={(value) => setName(value)}
            className="bg-[#252b3d] border-dashed border-2 border-gray-500 text-white p-4"
            placeholder="Enter session name"
          />
        </SectionContainer>

        <OverviewDetailContainer isBreak={session.type === TYPE_BREAK}>
          {/* Session Overview */}
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Session Overview</SectionTitle>
              <GenerateOverviewButton
                session={session}
                saveSession={saveSession}
              />
            </SectionHeader>
            <StyledMarkdownEditor data={overview} onChange={setOverview} />
          </SectionContainer>

          {/* Session Details */}
          <SectionContainer>
            <SectionHeader>
              <SectionTitle>Session Details</SectionTitle>
              <GenerateDetailsButton
                session={session}
                saveSession={saveSession}
              />
            </SectionHeader>
            <StyledMarkdownEditor data={details} onChange={setDetails} />
          </SectionContainer>
        </OverviewDetailContainer>

        <DeleteButton type={type} onConfirm={handleDelete} />
      </Content>
    </Page>
  )
}

const GenerateOverviewButton = (props: {
  session: Session
  saveSession: () => Promise<Session> | undefined
}) => {
  const {saveSession, session} = props
  const {blocks, profileId} = useObieService()
  const {routes} = useOrganization()
  const history = useHistory()

  const destinationBlock = blocks.find(
    (b) => b.pag_session_type === `${session.type}_overview`,
  )

  // No Block yet for this Session type's Overview? Don't show the button.
  if (!destinationBlock) {
    return null
  }

  const handleClick = () => {
    const pRoute = profileRoute(
      routes,
      String(destinationBlock.category.id),
      String(profileId),
    ).root

    const destination = `${pRoute}?blockId=${destinationBlock.id}&sessionId=${session.id}`

    return saveSession()?.then(() => history.push(destination))
  }

  return <StyledButton onClick={handleClick}>Generate Overview</StyledButton>
}

const GenerateDetailsButton = (props: {
  session: Session
  saveSession: () => Promise<Session> | undefined
}) => {
  const {saveSession, session} = props
  const {blocks, profileId} = useObieService()
  const {routes} = useOrganization()
  const history = useHistory()

  const destinationBlock = blocks.find(
    (b) => b.pag_session_type === `${session.type}_details`,
  )

  // No Block yet for this Session type's Details? Don't show the button.
  if (!destinationBlock) {
    return null
  }

  const handleClick = () => {
    const pRoute = profileRoute(
      routes,
      String(destinationBlock.category.id),
      String(profileId),
    ).root

    const destination = `${pRoute}?blockId=${destinationBlock.id}&sessionId=${session.id}`

    return saveSession()?.then(() => history.push(destination))
  }

  return <StyledButton onClick={handleClick}>Generate Details</StyledButton>
}

const DeleteButton = (props: {type: SessionType; onConfirm: () => void}) => {
  const {type, onConfirm} = props

  if (type === TYPE_WELCOME || type === TYPE_CLOSING) {
    return null
  }

  return (
    <DarkThemeProvider>
      <ConfirmDialog
        title="Delete Session?"
        description="Are you sure you want to delete this session?"
        confirmLabel="Delete"
        onConfirm={onConfirm}
      >
        {(showConfirmation) => (
          <StyledDeleteButton
            size="large"
            fullWidth
            color="danger"
            variant="outlined"
            onClick={showConfirmation}
          >
            Delete
          </StyledDeleteButton>
        )}
      </ConfirmDialog>
    </DarkThemeProvider>
  )
}

function useRouteSession() {
  const {sessions, pagId} = usePag()
  const {day: dayIndex, session: sessionIndex} = useParams<{
    day: string
    session: string
  }>()

  if (pagId === null || dayIndex === null || sessionIndex === null) {
    return null
  }

  if (!sessions[parseInt(dayIndex)]) {
    return null
  }

  if (!sessions[parseInt(dayIndex)][parseInt(sessionIndex)]) {
    return null
  }

  const session = sessions[parseInt(dayIndex)][parseInt(sessionIndex)]
  if (!session) {
    return null
  }

  return {dayIndex, sessionIndex, ...session}
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: ${(props) => props.theme.spacing[30]};
  padding-right: ${(props) => props.theme.spacing[30]};
  margin-bottom: 4rem;
`

const TypeEditorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  * {
    color: #ffffff !important;
  }

  input {
    background: transparent;
    border: 1px solid #ffffff;
  }

  ${Box} {
    margin: 0;
  }
`

const TypeLabel = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
  margin: 0;
  color: #ffffff;
`

const Title = styled.h1`
  font-size: 2.25rem;
  font-weight: bold;
`

const StyledTextField = styled(TextField)`
  input {
    background: transparent;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
`

const SectionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`

const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`

const StyledSelect = styled(Select)`
  margin: 0;

  > div > div {
    background: transparent;
  }
`

const StyledMarkdownEditor = styled(MarkdownEditor)`
  border: 1px solid #ffffff;
  padding: 1rem 0.5rem;
  border-radius: 6px;
`

export const StyledDeleteButton = styled(Button)`
  font-size: 1.5rem;
  padding: 1.25rem 0;
  width: 100%;

  &:hover {
    background: ${(props) => props.theme.colors.error} !important;
    color: #ffffff;
  }
`
const OverviewDetailContainer = styled.div<{
  isBreak: boolean
}>`
  display: ${(props) => (props.isBreak ? 'none' : 'block')};
`
