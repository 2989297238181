import React, {useCallback} from 'react'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/TimerConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useAddTranslation, useRemoveTranslations} from 'Event/LanguageProvider'

export default function TimerConfig(props: {
  sectionId: string
  countDownTimer: CountDownTimerSettings
  id: string
}) {
  const {id, countDownTimer, sectionId} = props

  const save = useSaveTemplate()
  const removeTranslations = useRemoveTranslations()
  const addTranslation = useAddTranslation()

  const handleSave = (data: any) => {
    const {dashboardSections} = data
    const {
      [sectionId]: {
        items: {
          [id]: {labels, description, separator, ...others},
        },
      },
    } = dashboardSections

    save({
      localization: addTranslation({
        [`dashboardSections_${sectionId}_items_${id}_labels_days`]: labels.days,
        [`dashboardSections_${sectionId}_items_${id}_labels_hours`]: labels.hours,
        [`dashboardSections_${sectionId}_items_${id}_labels_minutes`]: labels.minutes,
        [`dashboardSections_${sectionId}_items_${id}_labels_seconds`]: labels.seconds,
        [`dashboardSections_${sectionId}_items_${id}_description`]: description,
        [`dashboardSections_${sectionId}_items_${id}_separator`]: separator,
      }),
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: {
              ...others,
              labels: {
                days: `{{dashboardSections_${sectionId}_items_${id}_labels_days}}`,
                hours: `{{dashboardSections_${sectionId}_items_${id}_labels_hours}}`,
                minutes: `{{dashboardSections_${sectionId}_items_${id}_labels_minutes}}`,
                seconds: `{{dashboardSections_${sectionId}_items_${id}_labels_seconds}}`,
              },
              description: `{{dashboardSections_${sectionId}_items_${id}_description}}`,
              separator: `{{dashboardSections_${sectionId}_items_${id}_separator}}`,
            },
          },
        },
      },
    })
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of timer to remove.")
    }

    save({
      localization: removeTranslations([
        `dashboardSections_${sectionId}_items_${id}_labels_days`,
        `dashboardSections_${sectionId}_items_${id}_labels_hours`,
        `dashboardSections_${sectionId}_items_${id}_labels_minutes`,
        `dashboardSections_${sectionId}_items_${id}_labels_seconds`,
        `dashboardSections_${sectionId}_items_${id}_description`,
        `dashboardSections_${sectionId}_items_${id}_separator`,
      ]),
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [id, sectionId, save, removeTranslations])

  return (
    <Config title="Counterdown Timer" onSave={handleSave} onRemove={remove}>
      <SettingsPanel>
        <Settings
          countDownTimer={countDownTimer}
          id={id}
          sectionId={sectionId}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling
          countDownTimer={countDownTimer}
          id={id}
          sectionId={sectionId}
        />
      </StylingPanel>
    </Config>
  )
}
