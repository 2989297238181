import React, {useEffect, useState} from 'react'
import Dialog from 'lib/ui/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Block,
  Category,
  Profile,
  useObieService,
} from 'organization/Obie/ObieServiceProvider'
import styled from 'styled-components'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {onUnknownChangeHandler} from 'lib/dom'
import {Controller, UseFormMethods, useForm} from 'react-hook-form'
import Button from 'lib/ui/Button'

export interface RequiredCategory extends Category {
  blocks: Block[]
}

export default function BlockCreateDependencies(props: {
  block: Block
  onClose: () => void
  startProcess: (profileId: number, formData: any) => void
  open: boolean
  categories: RequiredCategory[]
}) {
  const {block, onClose, open, startProcess, categories} = props

  const {handleSubmit, control} = useForm()
  const {profileId} = useObieService()

  const submit = (data: any) => {
    if (profileId === undefined) {
      return
    }

    startProcess(profileId, data)
  }

  if (!open || categories.length === 0) {
    return null
  }

  return (
    <Dialog expandable={false} open onClose={onClose}>
      <DialogTitle>{block.block} depends on Completions</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(submit)}>
          {categories.map((category) => (
            <DependencyCategoryFields
              key={category.id}
              category={category}
              control={control}
            />
          ))}

          <StyledButton type="submit" variant="contained" color="primary">
            Continue
          </StyledButton>
          <Button
            type="button"
            variant="contained"
            color="grey"
            onClick={onClose}
          >
            Cancel
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export function getRequiredCategories(target: Block, blocks: Block[]) {
  const categories: Record<string, RequiredCategory> = {}

  // Iterate the listing of ALL Blocks to see which (if any) exist in our target's
  // dependencies. "Target" is the Block/Completion that is being created.
  for (const block of blocks) {
    // Skip when THIS Block's ID is not in our target's dependencies.
    if (!target.dependencies.includes(block.id)) {
      continue
    }

    // When THIS Block's ID happens to be in the target's dependencies, we need
    // to include this Category, so we can process it properly.
    const existingCategory = categories[block.category.id]
    const existingBlocks = existingCategory?.blocks || []

    categories[block.category.id] = {
      ...block.category,
      blocks: [...existingBlocks, block],
    }
  }

  return Object.values(categories)
}

function DependencyCategoryFields(props: {
  category: RequiredCategory
  control: UseFormMethods['control']
}) {
  const {category, control} = props
  const [targetProfileId, setTargetProfileId] = useState<number | null>(null)
  const {categoryId: currentCategoryId, profileId} = useObieService()

  // If the category.id we're processing is the same as the Block being created's
  // category.id... we set this flag to indicate that we're inside the same
  // category, for comparison below.
  const isSameCategory = category.id === currentCategoryId

  useEffect(() => {
    if (targetProfileId) {
      return
    }

    // If we happen to be inside the same Category as the Block being created,
    // we set the targetProfileId value to whatever the current profile ID is,
    // since we're not going to show a profile selector in this case.
    if (isSameCategory) {
      setTargetProfileId(profileId || 0)
      // Short-circuit because we don't want to evaluate how many profiles there
      // are, we're using whatever the currently selected profile is.
      return
    }

    // If there are no profiles in the Category, set the targetProfileId to 0 to
    // indicate the "Default Profile" - this does not exist in the result set
    // from the backend.
    if (!category.profiles.length) {
      setTargetProfileId(0)
    }
  }, [category, isSameCategory, profileId, targetProfileId])

  // We only want to show a ProfileSelector, if we NOT in the same category as
  // the Block being created. Within the same category has to stick to the same
  // Profile.
  const shouldSelectProfile =
    !isSameCategory && Boolean(category.profiles.length)

  return (
    <>
      <h2>{category.name}</h2>
      {shouldSelectProfile && (
        <ProfileSelector
          profiles={category.profiles}
          onSelect={setTargetProfileId}
          value={targetProfileId}
        />
      )}
      {targetProfileId !== null && (
        <AnswerSetContainer>
          {category.blocks.map((block) => (
            <AnswerSetSelector
              key={`${block.id}-${targetProfileId}`}
              block={block}
              control={control}
              targetProfileId={targetProfileId}
            />
          ))}
        </AnswerSetContainer>
      )}
    </>
  )
}

function ProfileSelector(props: {
  profiles: Profile[]
  onSelect: (profileId: number) => void
  value: number | null
}) {
  const {profiles, onSelect, value} = props
  return (
    <Select
      onChange={onUnknownChangeHandler((value) => onSelect(Number(value)))}
      fullWidth
      label="Profile's Completions to use"
      value={value?.toString() ?? ''}
    >
      {profiles.map((profile) => (
        <Option key={profile.id} value={profile.id.toString()}>
          {profile.name}
        </Option>
      ))}
    </Select>
  )
}

function AnswerSetSelector(props: {
  block: Block
  control: UseFormMethods['control']
  targetProfileId: number
}) {
  const {block, control, targetProfileId} = props

  const answerSets = block.answer_sets.filter((answerSet) => {
    return answerSet.profile_id === targetProfileId && answerSet.complete
  })

  if (answerSets.length === 1) {
    return (
      <Controller
        name={block.prompt.name}
        control={control}
        defaultValue={answerSets[0].id}
        render={({onChange, value}) => (
          <input
            type="hidden"
            name={block.prompt.name}
            value={value}
            onChange={onChange}
          />
        )}
      />
    )
  }

  if (answerSets.length === 0) {
    return (
      <Controller
        name={block.prompt.name}
        control={control}
        defaultValue={''}
        render={({value, onChange}) => (
          <StyledSelect
            label={block.block}
            fullWidth
            required
            value={value}
            onChange={onUnknownChangeHandler(onChange)}
            aria-label={`pick completed block ${block.id}`}
          >
            <Option value="">No Complete Blocks</Option>
          </StyledSelect>
        )}
      />
    )
  }

  return (
    <Controller
      name={block.prompt.name}
      control={control}
      defaultValue={''}
      render={({value, onChange}) => (
        <StyledSelect
          label={block.block}
          fullWidth
          required
          value={value}
          hidden={block.answer_sets.length === 1}
          onChange={onUnknownChangeHandler(onChange)}
          aria-label={`pick completed block ${block.id}`}
        >
          {answerSets.map((answerSet) => (
            <Option value={answerSet.id} key={answerSet.id}>
              {answerSet.name}
            </Option>
          ))}
        </StyledSelect>
      )}
    />
  )
}

/**
 * Attempts to get dependency data for all required categories. If any category
 * requires a profile selection, or a block requires an answer set selection,
 * then this function will return null.
 *
 * @param categories
 */
export function getCompletedDependencyData(
  categories: RequiredCategory[],
): Record<string, any> | null {
  const data: Record<string, any> = {}

  for (const category of categories) {
    for (const block of category.blocks) {
      // If there is more than zero profiles, ask the user to pick one. More than
      // zero, because everyone has the Default Profile. If there happens to be
      // more than just the Default Profile, user needs to pick one.
      if (category.profiles.length) {
        return null
      }

      const profile = category.profiles[0]
      const answerSets = block.answer_sets.filter(
        (answerSet) =>
          answerSet.profile_id === (profile?.id || 0) && answerSet.complete,
      )

      // Only able to auto-select if there is only one answer set.
      if (answerSets.length !== 1) {
        return null
      }

      data[block.prompt.name] = answerSets[0].id
    }
  }

  return data
}

const StyledSelect = styled(Select)<{
  hidden?: boolean
}>`
  display: ${(props) => (props.hidden === true ? 'none' : 'block')};
`

const StyledButton = styled(Button)`
  margin: ${(props) => props.theme.spacing[3]} 0;
  margin-right: ${(props) => props.theme.spacing[3]};
`

const AnswerSetContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing[8]};
`
