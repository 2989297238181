import React from 'react'
import styled from 'styled-components'
import {useAttendeeVariables} from 'Event'
import TextContent from 'lib/ui/form/TextEditor/Content'
import Typography from '@material-ui/core/Typography'
import QuestionIcon from 'Event/Sponsors/QuestionIcon'
import {Sponsor} from 'Event/Sponsors'

export const SPONSOR_QUESTION_ICON_PLACEHOLDER = 'http://placehold.jp/50x50.png'

type BodyProps = {
  sponsor: Sponsor
  id: string
  toggleForm: () => void
}

export default function Body(props: BodyProps) {
  const {sponsor, id} = props
  const v = useAttendeeVariables()

  return (
    <Box>
      <SponsorHeader>
        <StyledTypography variant="h5">{v(sponsor.name)}</StyledTypography>
        <StyledQuestionIcon
          sponsor={sponsor}
          onClick={props.toggleForm}
          id={id}
        />
      </SponsorHeader>
      <TextContent>{v(sponsor.description)}</TextContent>
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
`

const StyledTypography = styled(Typography)`
  font-family: inherit;
`

const StyledQuestionIcon = styled(QuestionIcon)`
  margin-right: 10px;
  width: 20%;
  max-width: 80px;
`

const SponsorHeader = styled.div`
  display: flex;
  justify-content: space-between;
`
