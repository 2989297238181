import React, {useState} from 'react'
import {useLanguage} from 'Event/LanguageProvider'
import styled from 'styled-components'
import {Language} from 'Event/LanguageProvider/language'
import Menu from 'lib/ui/Menu'
import MenuItem from 'lib/ui/Menu/MenuItem'
import Button from 'lib/ui/Button'
import {ChevronDownIcon} from 'lib/ui/Icon'

export default function LanguageSelector() {
  const {current, set, defaultLanguage} = useLanguage()
  const {languages, translationsEnabled} = useLanguage()
  const [dialogVisible, setDialogVisible] = useState(false)

  const toggleDialog = () => setDialogVisible(!dialogVisible)

  const name = (language: Language) => {
    if (language.name === defaultLanguage) {
      return `${language.name} (Default)`
    }

    return language.name
  }

  const handleSelect = (language: Language) => {
    set(language)
    toggleDialog()
  }

  if (!translationsEnabled) {
    return null
  }

  return (
    <Menu
      button={({open}) => (
        <StyledButton
          aria-label="event language menu"
          variant="text"
          color="primary"
          onClick={open}
        >
          <span>{current ?? defaultLanguage}</span>
          <ChevronDownIcon />
        </StyledButton>
      )}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {languages.map((language) => (
        <MenuItem key={language.name} onClick={() => handleSelect(language)}>
          {name(language)}
        </MenuItem>
      ))}
    </Menu>
  )
}

const StyledButton = styled(Button)`
  i {
    margin-left: 0.5rem;
  }
`
