import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {PencilIcon} from 'lib/ui/Icon'
import Select from 'lib/ui/Select'
import Option from 'lib/ui/Select/Option'
import {profileRoute} from 'organization/Obie/ObieRoutes'
import {Category} from 'organization/Obie/ObieServiceProvider'
import RenameProfileDialog from 'organization/Obie/RenameProfileDialog'
import {useOrganization} from 'organization/OrganizationProvider'

export default function CategoryProfileSelector(props: {
  category: Category
  profileId: number | undefined
}) {
  const {category, profileId} = props
  const history = useHistory()
  const {routes} = useOrganization()

  const [renameProfileOpen, setRenameProfileOpen] = useState<boolean>(false)
  const [renameProfileId, setRenameProfileId] = useState<number | undefined>()

  const toggleRenameProfileDialog = (profileId?: number) => {
    if (profileId !== undefined) {
      setRenameProfileId(profileId)
      setRenameProfileOpen(true)

      return
    }

    setRenameProfileId(undefined)
    setRenameProfileOpen(false)
  }

  const handleRenameClose = () => toggleRenameProfileDialog()

  // The <Select /> requires an onChange, so here we go.
  const handleNoOp = () => {}

  const goToProfile = (categoryId: number, profileId: number | undefined) => {
    if (profileId === undefined) {
      return
    }

    history.push(
      profileRoute(routes, String(categoryId), String(profileId)).root,
    )
  }

  return (
    <>
      <RenameProfileDialog
        profileId={renameProfileId}
        onClose={handleRenameClose}
        onSubmit={goToProfile}
        open={renameProfileOpen}
      />

      <Container>
        <StyledLabel>{`${category.name}: `}</StyledLabel>
        <StyledSelect
          onChange={handleNoOp}
          aria-label="pick value"
          value={String(profileId || '0')}
        >
          {category.profiles.map((profile, index) => {
            // Same "handling option clicks..." bit from above, except
            // specific to the profile options, not just the categories.
            const handleClickProfile = () => {
              goToProfile(category.id, profile.id)
            }
            const handleClickEditProfile = () => {
              toggleRenameProfileDialog(profile.id)
            }

            return (
              <StyledOption aria-label="blerp" key={index} value={profile.id}>
                <div onClick={handleClickProfile}>{profile.name}</div>
                <StyledPencil
                  onClick={handleClickEditProfile}
                  title={`Modify Profile name: ${profile.name}`}
                />
              </StyledOption>
            )
          })}
        </StyledSelect>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
`

const StyledSelect = styled(Select)`
  .MuiInput-input > div {
    margin-right: ${(props) => props.theme.spacing[4]};
  }
  .MuiInput-input > i {
    display: none;
  }
`

const StyledLabel = styled.label`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  margin-right: ${(props) => props.theme.spacing[4]};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }
`

const StyledOption = styled(Option)`
  justify-content: space-between;
  padding: 0;

  & > .MuiInput-input {
    display: flex;
  }

  & > div:first-child {
    margin: ${(props) => props.theme.spacing[2]}
      ${(props) => props.theme.spacing[4]};
    width: 100%;
  }
`

const StyledPencil = styled(PencilIcon)<{
  onClick: (profileId: number) => void
}>`
  margin: 0 ${(props) => props.theme.spacing[4]};
  &:hover {
    color: ${(props) => props.theme.colors.error};
  }
`
