import {FormSubmission} from 'organization/Event/Form/FormModeration/Question'
import {parseISO} from 'date-fns'

export function sortSubmissions(a: FormSubmission, b: FormSubmission) {
  if (a.position === 0 && b.position === 0) {
    return parseISO(a.saved_at).getTime() - parseISO(b.saved_at).getTime()
  }

  if (a.position === 0) {
    return 1
  }

  if (b.position === 0) {
    return -1
  }

  return a.position - b.position
}

export function sortSubmissionsReversed(a: FormSubmission, b: FormSubmission) {
  return b.position - a.position
}
