import React from 'react'
import styled from 'styled-components'
import {ReactComponent as Bot} from 'assets/images/icons/white-bot.svg'
import {RelativeLink} from 'lib/ui/link/RelativeLink'
import {useOrganization} from 'organization/OrganizationProvider'
import Button from 'lib/ui/Button'

export default function Header({onBack}: {onBack?: () => void}) {
  const {routes} = useOrganization()

  return (
    <Box>
      <Logo>
        <TitleBox to={routes.obie.root}>
          <IconBox>
            <Bot />
          </IconBox>
          <Title>OBIE</Title>
        </TitleBox>
      </Logo>
      {onBack && <BackButton onClick={onBack}>Back</BackButton>}
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled.div`
  display: flex;
`

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleBox = styled(RelativeLink)`
  display: inline-flex;

  padding: ${(props) => props.theme.spacing[8]};

  &:hover {
    text-decoration: none !important;
  }
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: ${(props) => props.theme.spacing[2]};
`

const BackButton = styled(Button)`
  background: ${(props) => props.theme.colors.primary} !important;
  color: #ffffff;

  &:hover {
    opacity: 0.8;
  }
`
